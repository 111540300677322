import {
  Box,
  CloseButton,
  Divider,
  Flex,
  Text,
  Tag,
  Spinner,
  TagLeftIcon,
  TagLabel,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import {
  MdFavorite,
  MdFavoriteBorder,
  MdOutlineDownload,
  MdOutlinePerson,
} from "react-icons/md";
import {
  MdsCrosswordOutlined,
  MdsVisibilityRound,
} from "react-icons-with-materialsymbols/mds";
import ReactFlow, {
  Node,
  Background,
  Edge,
  ReactFlowProvider,
  ReactFlowInstance,
  useNodesState,
  useEdgesState,
} from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { Icon } from "@/design/components/icon";
import { IconButton } from "@/design/components/icon-button";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { ApiErrorResponse } from "@/types";
import { getRelativeTime } from "@/utils/date-convertor";
import { closestColor } from "@/utils/get-color";

import {
  currentFlowItem,
  setCurrentFlowStoreItem,
  setShowFlowStore,
  useGetFlowQuery,
} from "../..";
import { useCreateFlowNodes } from "../../hooks/useAddFlowToWorkflow";
import useManageFlowFavorites from "../../hooks/useManageFlowFavorites";
import { getEditingAllowed } from "../../redux";
import { FlowSchema, NodeType } from "../../types";
import { getDistinctAnalyticsNodes } from "../../utils/flow-utils";
import { createReactflowNodesFromFlow } from "../../utils/transform-response";
import { CustomEdge, DataNode } from "../custom-node";
import FlowNode from "../custom-node/flow-node";

const FlowDetails = () => {
  const currentFlow = useAppSelector(currentFlowItem)?.flow as FlowSchema;
  const currentFlowType = useAppSelector(currentFlowItem)?.flowType;
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const [flowInstance, setFlowInstance] = useState<ReactFlowInstance>();

  const isEditingAllowed = useAppSelector(getEditingAllowed);
  const [flowNodes, setFlowNodes, onFlowNodeChange] = useNodesState<Node[]>([]);
  const [flowEdges, setFlowEdges, onFlowEdgesChange] = useEdgesState<Edge[]>(
    []
  );
  const { data, isLoading, isError } = useGetFlowQuery(
    { flowId: currentFlow ? currentFlow.nodeId : "" },
    { skip: !currentFlow }
  );

  const {
    addToFavorite,
    removeFavorite,
    isLoading: markingFav,
  } = useManageFlowFavorites();

  const { addFlow } = useCreateFlowNodes();

  const previewNodeTypes = useMemo(
    () => ({ "custom-node": DataNode, "group-node": FlowNode }),
    []
  );
  const previewEdgeTypes = useMemo(() => ({ "custom-edge": CustomEdge }), []);

  const defaultPreviewEdgeOptions = {
    type: "custom-edge",
    zIndex: 1001,
  };

  useEffect(() => {
    if (!data?.response.data) return;
    const { parentNode, nodesinFlow, edgesInFlow } =
      createReactflowNodesFromFlow(data.response.data.flow);

    setFlowNodes([parentNode, ...nodesinFlow]);
    setFlowEdges([...edgesInFlow]);
    setTimeout(() => {
      flowInstance?.fitView({
        padding: 0.8,
        duration: 50,
        minZoom: 0.2,
      });
    }, 100);
  }, [
    data,
    isError,
    toast,
    setFlowEdges,
    setFlowNodes,
    flowInstance,
    currentFlow,
  ]);

  if (!currentFlow) return null;

  const onClose = () => {
    dispatch(setCurrentFlowStoreItem(null));
  };

  const analyticsNodes = getDistinctAnalyticsNodes(flowNodes);

  const importFlow = () => {
    if (!isEditingAllowed) {
      toast({
        title: "Cannot add flow while workflow is running",
        status: ToastType.Warning,
      });
      return;
    }
    if (!data) return;
    try {
      addFlow(data.response.data!.flow);
      dispatch(setCurrentFlowStoreItem(null));
      dispatch(setShowFlowStore(false));
    } catch (error) {
      const err = error as ApiErrorResponse;
      toast({
        title: err.data.errors![0].detail ?? "Error occured while adding flow",
        status: "error",
      });
    }
  };

  const onFavorite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (markingFav) return;
    if (currentFlow.isFavorite) {
      await removeFavorite({
        flow: currentFlow,
        flowType: currentFlowType!,
      });
    } else {
      console.log("marking fav");
      await addToFavorite({
        flow: currentFlow,
        flowType: currentFlowType!,
      });
    }
  };

  const openPreviewModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.FLOW_PREVIEW,
        modalProps: { currentFlow, nodes: flowNodes, edges: flowEdges },
      })
    );
  };

  return (
    <Flex className="h-full w-[400px] overflow-hidden" direction="column">
      <Flex className="shrink w-full" justify="space-between" px={4} py={2}>
        <Text className="text-lg font-semibold shrink break-all">
          {currentFlow.displayName}
        </Text>
        <CloseButton flexShrink={0} onClick={onClose} />
      </Flex>
      {isLoading || isError ? (
        <Flex
          className="h-full grow w-full"
          align="center"
          justify="center"
          direction="column"
        >
          {isError ? (
            <Tag colorScheme="red" size="lg" variant="subtle">
              Error occured while fetching flow
            </Tag>
          ) : (
            <Spinner color="gray" />
          )}
        </Flex>
      ) : (
        <Flex className="flex flex-col max-h-full h-full overflow-y-auto">
          <Flex className="px-4 gap-2 pb-2" wrap={"wrap"}>
            {analyticsNodes.map((i, index) => (
              <Tag
                className="text-xs break-all whitespace-pre rounded-lg"
                key={i + index.toString()}
                colorScheme="orange"
              >
                <TagLeftIcon
                  className="stroke-[22]"
                  as={MdsCrosswordOutlined}
                />
                <TagLabel>{i}</TagLabel>
              </Tag>
            ))}
          </Flex>
          <Flex
            className="flex flex-col items-center overflow-y-auto grow gap-3"
            pb={4}
          >
            <ReactFlowProvider>
              <Box className="group w-[92%] min-h-[40%] bg-black-200 border border-gray-400 overflow-hidden rounded relative">
                <ReactFlow
                  nodes={flowNodes}
                  edges={flowEdges}
                  onNodesChange={onFlowNodeChange}
                  onEdgesChange={onFlowEdgesChange}
                  onInit={(instance) => setFlowInstance(instance)}
                  elementsSelectable={false}
                  defaultEdgeOptions={defaultPreviewEdgeOptions}
                  nodeTypes={previewNodeTypes}
                  edgeTypes={previewEdgeTypes}
                  nodesDraggable={false}
                  maxZoom={1.5}
                  minZoom={0.2}
                  zoomOnPinch={false}
                  zoomOnScroll={false}
                  panOnDrag={false}
                  proOptions={{ hideAttribution: true }}
                >
                  <Background id="bg-flow" className="bg-slate-50" />
                </ReactFlow>
                <Flex
                  className="group-hover:opacity-100 opacity-0 absolute h-full w-full top-0 left-0 z-999 bg-black-600 bg-opacity-30"
                  align={"center"}
                  justify={"center"}
                >
                  <Button
                    colorScheme="dark"
                    onClick={openPreviewModal}
                    leftIcon={<MdsVisibilityRound />}
                  >
                    Open Preview
                  </Button>
                </Flex>
              </Box>
            </ReactFlowProvider>
            <Flex
              align={"start"}
              justify={"space-between"}
              gap={2}
              w={"100%"}
              px={4}
            >
              <Flex align={"center"} flex={0.5} gap={2}>
                <Icon
                  as={MdOutlinePerson}
                  size="lg"
                  className="bg-gray-50 rounded-full p-1"
                />
                <Text className="font-semibold">Anurag Deore</Text>
              </Flex>
              <Flex justify={"end"} wrap={"wrap"} flex={0.5} gap={2}>
                {currentFlow.tags.map((tag, index) => (
                  <Tag
                    key={tag + index}
                    colorScheme={closestColor(tag)}
                    size="sm"
                    variant="subtle"
                  >
                    {tag}
                  </Tag>
                ))}
              </Flex>
            </Flex>
            <Flex
              className="text-sm"
              align="center"
              justify="space-between"
              gap={2}
              w={"100%"}
              px={4}
            >
              <div>
                <span className="text-gray-600">Last Used : </span>
                <span className="font-medium">
                  {getRelativeTime(currentFlow.uploadDate)}{" "}
                </span>
              </div>
              <div>
                <span className="text-gray-600">Uploaded: </span>
                <span className="font-medium">
                  {getRelativeTime(currentFlow.lastUsedAt)}
                </span>
              </div>
            </Flex>
            <Box className="grow w-full break-all" px={4} pt={2}>
              {currentFlow.description}
            </Box>
          </Flex>
          <Divider />
          <Flex className="shrink-0 grow-0" gap={3} p={4}>
            {currentFlow.isFavorite ? (
              <IconButton
                variant={"ghost"}
                isLoading={markingFav}
                colorScheme="red"
                aria-label="Favorite"
                title="Remove from Favorites"
                //eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onFavorite}
                icon={<Icon as={MdFavorite} />}
              />
            ) : (
              <IconButton
                variant={"ghost"}
                isLoading={markingFav}
                colorScheme="dark"
                aria-label="Favorite"
                title="Add to Favorites"
                //eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onFavorite}
                icon={<Icon as={MdFavoriteBorder} />}
              />
            )}
            <Button
              colorScheme="orange"
              iconSpacing={2}
              onClick={importFlow}
              className="w-full items-center justify-center"
              rightIcon={<Icon as={MdOutlineDownload} size="sm" />}
            >
              Import
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default FlowDetails;
