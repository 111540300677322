import { ConnectorSources, IDataSource } from "../types";

export const groupSourcesByCategory = (sources: IDataSource[]) => {
  const grouped: ConnectorSources = {
    All: [],
  };

  sources.forEach((source) => {
    source.dataCategory.forEach((category) => {
      if (!grouped[category]) {
        grouped[category] = [];
      }

      grouped[category].push(source);
      grouped.All.push(source);
    });
  });

  return grouped;
};
