import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import Fuse from "fuse.js";
import { useState } from "react";
import { MdSearch } from "react-icons/md";
import { MdsSearchRound } from "react-icons-with-materialsymbols/mds";
import { useParams } from "react-router-dom";

import { useGetNodesListQuery } from "../../api";
import { NodeGroups, NodeType } from "../../types";
import { BaseNode } from "../custom-node/base-node";

import { BottomBarItem } from "./bottom-bar-item";

interface NodeTypeWithIndex {
  index: number;
  category: string;
  node: NodeType;
}

function flattenNodes(nodes: NodeGroups) {
  return Object.keys(nodes).reduce((acc, key, idx) => {
    // Iterating over array of nodes
    const nodesArray = nodes[key].map((node, index) => ({
      index,
      category: key,
      node,
    }));

    return [...acc, ...nodesArray];
  }, [] as NodeTypeWithIndex[]);
}

const SearchNodes = () => {
  const [search, setSearch] = useState<NodeTypeWithIndex[]>([]);
  const { analysisId } = useParams();

  const { data: nodes } = useGetNodesListQuery({
    analysisId: analysisId as string,
  });
  const data = nodes ? flattenNodes(nodes) : [];
  const fuse = new Fuse(data, {
    keys: ["node.displayName"],
    threshold: 0.3,
  });

  function searchFlows(e: React.ChangeEvent<HTMLInputElement>) {
    /**
     * @function
     * Searches the flows and sets the searched flows on input change
     */
    const searchValue = e.target.value;
    if (searchValue === "") {
      setSearch([]);
      return;
    }
    const results = fuse.search(searchValue);
    const filteredFlows = results.map((result) => result.item);
    setSearch(filteredFlows);
  }

  const closeSearch = () => {
    setSearch([]);
  };
  return (
    <BottomBarItem
      icon={<MdsSearchRound className="stroke-[12] w-[28px] h-[28px]" />}
      title="Search"
      onClose={closeSearch}
    >
      <Flex direction="column" overflow="hidden" w={"360px"} h={"50vh"}>
        <InputGroup borderBottom="1px solid #d4d4d4 !important">
          <InputLeftElement color={"gray.500"} pointerEvents="none">
            <MdSearch size={18} />
          </InputLeftElement>
          <Input
            className="!text-sm"
            border={"none"}
            onChange={searchFlows}
            onFocus={(e) => e.stopPropagation()}
            placeholder=""
            type="search"
          />
        </InputGroup>
        {search.length > 0 ? (
          <Flex wrap={"wrap"} gap={3} overflowY={"auto"} w={"100%"} p={3}>
            {search.map((node, idx) => (
              <BaseNode index={node.index} node={node.node} key={idx} />
            ))}
          </Flex>
        ) : (
          <Box
            className="text-sm h-full"
            alignItems={"center"}
            justifyContent={"center"}
            flexGrow={1}
            display={"flex"}
            maxW={"360px"}
          >
            Search Nodes, flows, and more...
          </Box>
        )}
      </Flex>
    </BottomBarItem>
  );
};

export default SearchNodes;
