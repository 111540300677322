import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  control: {
    _checked: {
      _disabled: {
        bg: "var(--chakra-colors-gray-600)",
        borderColor: "var(--chakra-colors-gray-600)",
        color: "white",
      },
      bg: "var(--chakra-colors-orange-400)",
      borderColor: "var(--chakra-colors-orange-400)",
      _hover: {
        borderColor: "var(--chakra-colors-red-600)",
        bg: "var(--chakra-colors-red-600)",
      },
    },
  },
});
export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
});
