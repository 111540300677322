import { createBrowserRouter, Outlet } from "react-router-dom";

import ModalWrapper from "@/components/modal-wrapper";
import { isRegionSelectionPending } from "@/utils/baseUrls";

import RegionSelection from "./pre-auth/select-region";

export const PreAuthRouter = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: () => {
      if (isRegionSelectionPending()) {
        return <Outlet />;
      }
      return (
        <>
          <ModalWrapper />
          <Outlet />
        </>
      );
    },

    children: [
      {
        index: true,
        Component: () => <RegionSelection />,
      },
    ],
  },
]);

export const PostAuthRouter = createBrowserRouter([
  {
    path: "/",
    Component: () => {
      return (
        <>
          <ModalWrapper />
          <Outlet />
        </>
      );
    },
    children: [
      {
        index: true,
        lazy: async () => {
          try {
            const { SelectOrg } = await import("@/routes/pre-auth/select-org");
            return { Component: SelectOrg };
          } catch (error) {
            console.error(error);
            throw new Error("Failed to load SelectOrg component");
          }
        },
      },
      {
        path: "/logout",
        lazy: async () => {
          try {
            const { LogoutRedirect } = await import("@/routes/pre-auth/logout");
            return { Component: LogoutRedirect };
          } catch (error) {
            console.error(error);
            throw new Error("Failed to load LogoutRedirect component");
          }
        },
      },
    ],
  },
]);
