import { Flex } from "@chakra-ui/react";
import { captureException } from "@sentry/react";
import { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unstable_usePrompt, useNavigate, useParams } from "react-router-dom";

import {
  useGetTransformationsQuery,
  usePutEDAMutation,
} from "@/features/data-transformation/api";
import { useBackListener } from "@/features/data-transformation/hooks";
import { useNavigationWarning } from "@/features/data-transformation/hooks/useNavigationWarning.ts";
import {
  resetState,
  saveEditCalls,
  setEdaMetaData,
  setStatusMessage,
  triggerFetch,
} from "@/features/data-transformation/redux";
import {
  IAnalysisEdaMetaData,
  IPutEDARequest,
} from "@/features/data-transformation/types";
import {
  CUSTOM_QUERY,
  FETCH_TYPE,
  ORIGIN,
} from "@/features/data-transformation/utils";
import {
  hidePanel,
  selectPanel,
  triggerRun,
  useLazyGetWorkflowQuery,
} from "@/features/workflow-studio";
import { currentWorkflowId } from "@/features/workflow-studio/redux";
import { WORKFLOW_PANELS } from "@/features/workflow-studio/utils";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import { useAppSelector } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice.ts";

import {
  DataTransformationBottomBar,
  DataTransformationTable,
  RightPanel,
  StepsPanel,
  TransformationsPannel,
  MemoryLimitMessage,
} from "./components";

export const EdaMetaDataContext = createContext<
  Partial<
    IAnalysisEdaMetaData & {
      closeEda: () => void;
      saveEda: () => void;
      executeEda: () => void;
    }
  >
>({
  analysisId: undefined,
  edaId: undefined,
  edaFrom: ORIGIN.DataManager,
  closeEda: () => {},
  saveEda: () => {},
  executeEda: () => {},
});

export const DataTransformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setParamWithHistory, currentParam, removeCurrentParam } =
    useCustomQuery(CUSTOM_QUERY);
  const { datasetId, analysisId: analysisIdFromParam } = useParams();

  const transformPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataTransformationPanel)
  );

  const { modalType } = useAppSelector((state) => state.rootReducer.modals);

  const editCalls = useSelector(saveEditCalls);
  const workflowId = useAppSelector(currentWorkflowId);

  const analysisId: string = useMemo(
    () => analysisIdFromParam ?? transformPanel.analysisId,
    [analysisIdFromParam, transformPanel.analysisId]
  );
  const edaId = useMemo(() => currentParam ?? undefined, [currentParam]);

  const [putEDA] = usePutEDAMutation();
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();

  const edaFrom = datasetId ? ORIGIN.DataManager : ORIGIN.Workflow;

  const onCloseReset = () => {
    dispatch(resetState());
    dispatch(hidePanel(WORKFLOW_PANELS.DataTransformationPanel));
  };

  useBackListener(onCloseReset);

  const addEdaIdToUrl = (param: string) => {
    switch (edaFrom) {
      case ORIGIN.DataManager: {
        const pathname = window.location.pathname;
        const segments = pathname.split("/");
        const dataManagerIndex = segments.findIndex(
          (segment) => segment === "data-manager"
        );
        const isTransformPage =
          dataManagerIndex !== -1 && segments.length > dataManagerIndex + 1;
        // Check if there is an ID after `data-manager`
        if (isTransformPage) {
          setParamWithHistory(param);
        }
        break;
      }
      case ORIGIN.Workflow:
        setParamWithHistory(param);
        break;
    }
  };

  const callEDAApi = async (data: IPutEDARequest) => {
    dispatch(setStatusMessage("Loading dataset…"));
    try {
      const res = await putEDA(data).unwrap();
      const _data = res.response.data!;
      addEdaIdToUrl(_data.edaId);

      dispatch(
        setEdaMetaData({
          accessMode: _data.edaAccessMode,
          userHasWriteAccess: _data.userHasWriteAccess,
          title: _data.metadata?.datasetName ?? "Transform",
          activeRequests: _data.activeRequests,
          activeUser: _data.activeUser,
        })
      );
      dispatch(triggerFetch(FETCH_TYPE.STEPS));
    } catch (e) {
      switch (edaFrom) {
        case ORIGIN.DataManager:
          captureException(e);
          dispatch(
            openModal({ modalProps: {}, modalType: ModalTypes.FATAL_ERROR })
          );
          break;
        case ORIGIN.Workflow:
          onCloseReset();
          break;
      }
    }
  };

  useGetTransformationsQuery({});
  useNavigationWarning(editCalls > 0);
  unstable_usePrompt({
    message: "You have unsaved changes. Are you sure you want to leave?",
    when: ({ currentLocation, nextLocation }) => {
      console.log(modalType, "modaltype");
      if (modalType == ModalTypes.CLOSE_EDA) return false;

      return (
        editCalls > 0 && currentLocation.pathname !== nextLocation.pathname
      );
    },
  });

  useEffect(() => {
    let data: IPutEDARequest;
    switch (edaFrom) {
      case ORIGIN.DataManager:
        data = {
          origin: edaFrom,
          data: {
            datasetId: datasetId!,
          },
          analysisId: analysisId,
        };
        break;
      case ORIGIN.Workflow:
        if (!transformPanel.nodeId) return;
        data = {
          origin: edaFrom,
          data: {
            transformNodeData: {
              workflowNodeId: transformPanel.workflowNodeId,
              parameters: transformPanel.parameters,
            },
            workflowRunId: transformPanel.workflowRunId,
            workflowId: transformPanel.workflowId,
          },
          analysisId: transformPanel.analysisId,
        };
        break;
    }
    callEDAApi(data);
    return () => {
      dispatch(resetState());
    };
  }, [transformPanel.isVisible]);

  const closeEda = () => {
    removeCurrentParam();
    switch (edaFrom) {
      case ORIGIN.DataManager:
        navigate(`/analysis/${analysisId}/data-manager`);
        break;
      case ORIGIN.Workflow:
        dispatch(hidePanel(WORKFLOW_PANELS.DataTransformationPanel));
        break;
    }
  };

  const saveEda = () => {
    switch (edaFrom) {
      case ORIGIN.DataManager:
        break;
      case ORIGIN.Workflow:
        void getWorkflowDetails({
          workflowId: workflowId!,
        });
        break;
    }
  };

  const executeEda =  () => {
    switch (edaFrom) {
      case ORIGIN.DataManager:
        break;
      case ORIGIN.Workflow:
        dispatch(triggerRun(true));
        setTimeout(() => dispatch(triggerRun(false)), 1000);
        dispatch(hidePanel(WORKFLOW_PANELS.DataTransformationPanel));
        break;
    }
  };

  return (
    <EdaMetaDataContext.Provider
      value={{
        analysisId: analysisId,
        edaId: edaId,
        edaFrom,
        closeEda,
        saveEda,
        executeEda,
      }}
    >
      <Flex className="flex-col h-full w-full max-h-full overflow-auto">
        <DataTransformationBottomBar />
        <MemoryLimitMessage />
        <Flex className="flex-row h-full flex-1 max-h-full overflow-auto">
          <Flex className="flex-row h-full flex-1 max-h-full relative overflow-hidden bg-transparent">
            <TransformationsPannel />
            <StepsPanel />
            <Flex className="h-full w-full flex-1 overflow-hidden">
              <DataTransformationTable />
            </Flex>
          </Flex>
          <RightPanel />
        </Flex>
      </Flex>
    </EdaMetaDataContext.Provider>
  );
};
