import { useState, useEffect, useRef, useCallback } from "react";
import { MdsArrowOutwardRound } from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { DATA_UPLOAD_STATUS } from "@/utils/enums";

import { useGetOngoingUploadsQuery } from "../api";
import { getDescriptionForReviewData } from "../components/common/popup-description";
import { getStudioAnalysis, setReviewData } from "../redux";
import { IoRecord } from "../types";
import { ONGOING_UPLOADS_POLLING_INTERVAL } from "../utils/constants";

// showReview boolean is used to show the toast for review data.
const useOngoingUploads = ({
  showReview = false,
}: {
  showReview?: boolean;
}) => {
  const { analysisId } = useParams<{
    analysisId: string;
  }>();
  const previousStatusList = useRef<IoRecord[]>([]);
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const navigate = useNavigate();
  const studioAnalysis = useAppSelector(getStudioAnalysis);

  const {
    data: uploads,
    isError,
    isLoading,
  } = useGetOngoingUploadsQuery(
    { analysisId: analysisId! },
    {
      pollingInterval: ONGOING_UPLOADS_POLLING_INTERVAL, // shouldPoll ? : undefined,
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.response?.data?.results,
      }),
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      skip: !studioAnalysis,
    }
  );
  const openReviewData = useCallback(
    (dataset: IoRecord | undefined) => {
      if (!dataset) return;
      dispatch(setReviewData(dataset));
      if (!window.location.pathname.includes(`/data-manager/review`)) {
        navigate(`/analysis/${analysisId!}/data-manager/review/${dataset.id}`);
      }
      toast.closeAll();
    },
    [dispatch, navigate, analysisId, toast]
  );

  // useEffect(() => {
  //   if (isError) {
  //     setShouldPoll(false);
  //   }
  // }, [isError]);

  const isReadyForReview = (prevItem: IoRecord | undefined, item: IoRecord) => {
    const wasRunning =
      prevItem &&
      (prevItem.state === DATA_UPLOAD_STATUS.UPLOADING_TO_BRONZE_ZONE ||
        prevItem.state === DATA_UPLOAD_STATUS.UPLOADING_TO_CS_ZONE);
    const isCompleted = item?.state === DATA_UPLOAD_STATUS.REVIEW_PENDING;
    return wasRunning && isCompleted;
  };

  useEffect(() => {
    if (!uploads || !showReview) return;
    if (uploads?.length > 0 && previousStatusList.current.length > 0) {
      uploads.forEach((item: IoRecord) => {
        const prevItem = previousStatusList.current.find(
          (p) => p.id === item.id
        );
        if (isReadyForReview(prevItem, item)) {
          toast({
            id: item.id,
            isClosable: true,
            status: ToastType.Success,
            action: () => openReviewData(item),
            actionText: "Review Data",
            actionIcon: MdsArrowOutwardRound,
            title: "Upload Completed",
            description: getDescriptionForReviewData(item.name),
          });
        }
      });
    }
    previousStatusList.current = uploads ?? [];
  }, [openReviewData, toast, uploads, showReview]);

  return { uploads, isLoading, isError };
};

export default useOngoingUploads;
