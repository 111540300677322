import { Flex, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import clsx from "clsx";
import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import {
  currentActiveVersion,
  getPreviewVersion,
  isLLMEditable,
  setActiveVersion,
  setIsExecuteEnabled,
  showPromptHistory,
} from "../../redux";
import { AIModel, ModelConfig, TextGenVersion } from "../../types";
import { AIMODEL_DATATYPES } from "../../types/enums";

import { configureModelWithCurrentValues, setValueInVersion } from "./helper";
import RangeSelector from "./variants/range-selector";
import SimpleTagInput from "./variants/Tags-input";

const SettingsElements = ({
  allAIModels,
  isLoading,
}: {
  allAIModels: AIModel[];
  isLoading: boolean;
}) => {
  const activeVersion = useAppSelector(currentActiveVersion);
  const previewVersion = useAppSelector(getPreviewVersion);
  const isHistoryPanelOpen = useAppSelector(showPromptHistory);
  const isEditable = useAppSelector(isLLMEditable);

  const dispatch = useAppDispatch();

  const handleConfigChange = (
    id: string | undefined,
    value: number | string
  ) => {
    if (!id) return;
    const updatedVersion = setValueInVersion(activeVersion, id, value);
    dispatch(setIsExecuteEnabled(false));
    dispatch(setActiveVersion(updatedVersion as TextGenVersion));
  };

  const models = useMemo(
    () =>
      allAIModels.map((m) => ({
        label: m.displayName,
        value: m.id,
      })),
    [allAIModels]
  );

  const handleModelChange = (id: string | undefined) => {
    if (!id) return;
    if (id === activeVersion?.aiModel?.id) return;
    const newConfig = allAIModels.find((m) => m.id === id);
    const configuredModel = configureModelWithCurrentValues(
      activeVersion?.aiModel,
      newConfig
    );
    dispatch(setIsExecuteEnabled(false));
    dispatch(
      setActiveVersion({
        ...activeVersion,
        aiModel: configuredModel,
      } as TextGenVersion)
    );
  };

  const displayModel = useMemo(() => {
    if (isHistoryPanelOpen && previewVersion?.aiModel)
      return previewVersion?.aiModel;

    return activeVersion?.aiModel;
  }, [activeVersion, previewVersion, isHistoryPanelOpen]);

  const defaultSelectedModel = useMemo(() => {
    const modelValue = {
      label: "",
      value: "",
    };
    if (isHistoryPanelOpen) {
      modelValue.label = previewVersion?.aiModel?.displayName ?? "";
      modelValue.value = previewVersion?.aiModel?.id ?? "";
    } else if (activeVersion?.aiModel?.id) {
      modelValue.label = activeVersion?.aiModel?.displayName ?? "";
      modelValue.value = activeVersion?.aiModel?.id ?? "";
    }
    return modelValue;
  }, [previewVersion, activeVersion, isHistoryPanelOpen]);

  const renderFormElement = (item: ModelConfig) => {
    switch (item.datatype) {
      case AIMODEL_DATATYPES.FLOAT:
      case AIMODEL_DATATYPES.INTEGER:
        return (
          <RangeSelector
            onChange={handleConfigChange}
            config={item}
            isHistoryPanelOpen={isEditable}
          />
        );

      case AIMODEL_DATATYPES.ARRAY:
        return (
          <SimpleTagInput
            disabled={isEditable}
            maxTagLength={15}
            onChange={handleConfigChange}
            config={item}
            label="Stop Sequences"
            isRequired={true}
            helpText="Type Sequence and press Enter"
          />
        );

      default:
        return null;
    }
  };

  const isAllModelsEmpty = !isLoading && allAIModels.length < 1;
  if (isAllModelsEmpty) {
    return (
      <Flex
        className={clsx(
          "flex-col shrink-0 h-full grow border p-3 mx-3 mb-3 gap-3 rounded-md",
          isEditable && "bg-[#FCFCFC]"
        )}
      >
        <Text className="text-center">No Models Available</Text>
      </Flex>
    );
  }

  return (
    <>
      {displayModel && (
        <Flex
          className={clsx(
            "flex-col shrink-0 border p-3 mx-3 mb-3 gap-3 rounded-md flex-[0.9] overflow-y-auto",
            isEditable && "bg-[#FCFCFC]"
          )}
        >
          <Select
            options={models}
            selectedOptionStyle="check"
            onChange={(e) => handleModelChange(e?.value)}
            value={defaultSelectedModel}
            placeholder="Select model..."
            size={"sm"}
            maxMenuHeight={150}
            isDisabled={isEditable}
            className="z-[999]"
          />

          {displayModel.modelConfigs.map((item) => (
            <div key={item.name}>{renderFormElement(item)}</div>
          ))}
        </Flex>
      )}
    </>
  );
};

export default SettingsElements;
