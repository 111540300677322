import { useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { STATUS } from "@/utils/enums";

import { useMarkFlowAsFavoriteMutation } from "../api";
import { currentFlowItem, setCurrentFlowStoreItem } from "../redux";
import { FlowSchema } from "../types";
import { FLOW_TYPES } from "../utils/constants";

interface ToggleFavProps {
  flow: FlowSchema;
  flowType: string;
}

function useManageFlowFavorites() {
  const dispatch = useAppDispatch();
  const currentPreviewFlow = useAppSelector(currentFlowItem);
  const [markAsFavorite, { isLoading }] = useMarkFlowAsFavoriteMutation();
  const toast = useShowToast(undefined, undefined, true);

  const hideIfFavTab = (flow: FlowSchema) => {
    const isOpen =
      flow.nodeUsageInstanceId ===
      currentPreviewFlow?.flow?.nodeUsageInstanceId;
    const isFavTab = currentPreviewFlow?.flowType === FLOW_TYPES.FAVORITES;
    if (isOpen && isFavTab) {
      dispatch(setCurrentFlowStoreItem(null));
    }
  };

  const toggleFav = async ({
    flow,
    flowType,
    isFavorite,
  }: {
    flow: FlowSchema;
    flowType: string;
    isFavorite: boolean;
  }) => {
    if (isLoading) return;

    if (!flow.nodeId || !flow.nodeVersionId) {
      throw new Error("Flow not found");
    }
    const toastId = `${isFavorite ? "mark" : "unmark"}-fav-toast${flow.nodeId}`;

    try {
      toast({
        id: toastId,
        status: "loading",
        title: isFavorite ? "Adding to favorites" : "Removing from favorites",
      });

      const resp = await markAsFavorite({
        flowId: flow.nodeId,
        flowVersionId: flow.nodeVersionId,
        action: isFavorite ? "favorite" : "unfavorite",
        flowType,
      }).unwrap();

      if (resp?.status === STATUS.SUCCESS) {
        if (!isFavorite) hideIfFavTab(flow);

        toast.update(toastId, {
          title: `Flow ${flow.displayName} ${
            isFavorite ? "added to" : "removed from"
          } favorites`,
          status: "success",
        });
      }
    } catch (error) {
      toast.update(toastId, {
        title: `Error ${
          isFavorite ? "adding flow to" : "removing flow from"
        } favorites`,
        status: "error",
      });

      console.log(error);
    }
  };

  return {
    removeFavorite: (args: ToggleFavProps) =>
      toggleFav({ ...args, isFavorite: false }),
    addToFavorite: (args: ToggleFavProps) =>
      toggleFav({ ...args, isFavorite: true }),
    isLoading,
  };
}

export default useManageFlowFavorites;
