import { Button, useMultiStyleConfig, useTab } from "@chakra-ui/react";
import DatabaseFilled from "mdi-react/DatabaseIcon";
import DatabaseOutline from "mdi-react/DatabaseOutlineIcon";
import React, { ReactNode } from "react";
interface DataPreviewTabProps {
  children: ReactNode; // Specify string type for children prop
}
export const DataPreviewTab = React.forwardRef<
  HTMLDivElement,
  DataPreviewTabProps
>((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps["aria-selected"];

  const styles = useMultiStyleConfig("Tabs", tabProps);

  return (
    <Button
      __css={styles.tab}
      {...tabProps}
      className="flex items-center gap-1.5 shrink-0"
    >
      {isSelected ? (
        <DatabaseFilled size="1.25rem" />
      ) : (
        <DatabaseOutline size="1.25rem" />
      )}
      {tabProps.children}
    </Button>
  );
});
