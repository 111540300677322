import { useRef, useCallback, useEffect } from "react";

export const useSingletonDebounce = <T extends (...args: any) => any>(
  fn: T,
  delay: number,
  id?: string
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const functionRef = useRef(fn);

  // Update the function ref on each render, so the latest version is used
  functionRef.current = fn;

  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        functionRef.current(...args);
      }, delay);
    },
    [delay]
  );

  // If id changes, clear the existing timeout
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [id]);

  return debouncedFn;
};

type AnyFunction = (...args: any[]) => any;

export const useCancellableDebounce = <T extends AnyFunction>(
  fn: T,
  delay: number,
  id?: string
): [(...args: Parameters<T>) => void, () => void] => {
  // Using generation to track the latest debounce call
  const generationRef = useRef(0);

  const debouncedFn = useCallback(
    (...args: Parameters<T>) => {
      console.log("Debounced function called");
      const thisGeneration = ++generationRef.current;

      setTimeout(() => {
        // Only execute if this is still the latest generation
        if (thisGeneration === generationRef.current) {
          console.log("Executing function");
          fn(...args);
        } else {
          console.log("Skipping execution - cancelled");
        }
      }, delay);
    },
    [fn, delay]
  );

  const cancel = useCallback(() => {
    console.log("Cancel called - incrementing generation");
    // Increment generation to invalidate any pending executions
    generationRef.current++;
  }, []);

  return [debouncedFn, cancel];
};
