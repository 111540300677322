import {
  PayloadAction,
  createSelector,
  createSlice,
  current,
} from "@reduxjs/toolkit";

import { RootState } from "@/store";

import { nodeManagerApi } from "../api";
import { CTXMenuProps } from "../components/node-menu";
import { FlowSchema, NodeType } from "../types";
import { FLOW_TYPES, WORKFLOW_PANELS } from "../utils/constants";

interface PanelState {
  isVisible: boolean;
  nodeId?: string | null;

  [key: string]: any;
}

interface FlowStorePreviewItemState {
  flowType: FLOW_TYPES;
  flow?: FlowSchema | null;
}

// Define a type for the slice state
interface WorkflowUIState {
  bottombarVisible: boolean;
  currentPreviewNode: NodeType | null;
  showFlowStore: boolean;
  currentFlowStoreItem: FlowStorePreviewItemState | null;
  llmConfigPanel: PanelState;
  triggerRunButton: boolean;
  contextMenu: CTXMenuProps | null;
  //  Panels
  [WORKFLOW_PANELS.NodeInfoPanel]: PanelState;
  [WORKFLOW_PANELS.DataPreviewPanel]: PanelState;
  [WORKFLOW_PANELS.NodeConfigurationPanel]: PanelState;
  [WORKFLOW_PANELS.FlowStorePanel]: PanelState;
  [WORKFLOW_PANELS.LogsPanel]: PanelState;
  [WORKFLOW_PANELS.DataTransformationPanel]: PanelState;
  [WORKFLOW_PANELS.LLMConfigPanel]: PanelState;
}

// Define the initial state using that type
const initialState: WorkflowUIState = {
  bottombarVisible: true,
  currentPreviewNode: null,
  showFlowStore: false,
  triggerRunButton: false,
  currentFlowStoreItem: null,
  llmConfigPanel: { isVisible: false },
  contextMenu: null,
  //  Panels
  [WORKFLOW_PANELS.NodeInfoPanel]: { isVisible: false },
  [WORKFLOW_PANELS.DataPreviewPanel]: { isVisible: false },
  [WORKFLOW_PANELS.NodeConfigurationPanel]: { isVisible: false },
  [WORKFLOW_PANELS.FlowStorePanel]: { isVisible: false },
  [WORKFLOW_PANELS.LogsPanel]: { isVisible: false },
  [WORKFLOW_PANELS.DataTransformationPanel]: { isVisible: false },
  [WORKFLOW_PANELS.LLMConfigPanel]: { isVisible: false },
};

const workflowStudioSlice = createSlice({
  name: "workflow-studio-ui",
  initialState,
  reducers: {
    setPreviewNode: (state, action: PayloadAction<NodeType | null>) => {
      state.currentPreviewNode = action.payload;
    },
    setShowFlowStore: (state, action: PayloadAction<boolean>) => {
      state.showFlowStore = action.payload;
    },
    setCurrentFlowStoreItem: (
      state,
      action: PayloadAction<FlowStorePreviewItemState | null>
    ) => {
      state.currentFlowStoreItem = action.payload;
    },
    toggleLLMConfigPanel: (
      state,
      action: PayloadAction<{ show: boolean; nodeId?: string }>
    ) => {
      state.llmConfigPanel.isVisible = action.payload.show;
      if (action.payload.nodeId) {
        state.llmConfigPanel.nodeId = action.payload.nodeId;
      } else {
        state.llmConfigPanel.nodeId = null;
      }
    },
    triggerRun: (state, action: PayloadAction<boolean>) => {
      state.triggerRunButton = action.payload;
    },
    showPanel: (
      state,
      action: PayloadAction<{
        panel: keyof typeof WORKFLOW_PANELS;
        nodeId?: string;
        [key: string]: any;
      }>
    ) => {
      const { panel, nodeId = null, ...extraValues } = action.payload;
      // loop over WORKFLOW_PANELS enum and set the isVisible for all other panels to false
      Object.keys(WORKFLOW_PANELS).forEach((key) => {
        const panelKey = key as keyof typeof WORKFLOW_PANELS;
        state[panelKey].isVisible = panelKey === panel;
      });
      state.bottombarVisible = false;
      state[panel].nodeId = nodeId;
      state[panel] = { ...state[panel], ...extraValues };
    },
    hidePanel: (state, action: PayloadAction<keyof typeof WORKFLOW_PANELS>) => {
      state[action.payload].isVisible = false;
      state[action.payload].nodeId = null;
      state.bottombarVisible = true;
    },
    hideAllPanels: (state) => {
      Object.keys(WORKFLOW_PANELS).forEach((key) => {
        const panelKey = key as keyof typeof WORKFLOW_PANELS;
        state[panelKey].isVisible = false;
        state[panelKey].nodeId = null;
      });
      state.bottombarVisible = true;
    },
    setContextMenu: (state, action: PayloadAction<CTXMenuProps | null>) => {
      state.contextMenu = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addMatcher(
      nodeManagerApi.endpoints.markFlowAsFavorite.matchFulfilled,
      (state, action) => {
        const currentState = current(state).currentFlowStoreItem;
        if (currentState?.flow) {
          if (
            action.meta.arg.originalArgs.flowId === currentState.flow.nodeId
          ) {
            state.currentFlowStoreItem!.flow!.isFavorite =
              action.meta.arg.originalArgs.action === "favorite";
          }
        }
      }
    );
  },
});

export const {
  setPreviewNode,
  setCurrentFlowStoreItem,
  setShowFlowStore,
  toggleLLMConfigPanel,
  showPanel,
  hidePanel,
  hideAllPanels,
  triggerRun,
  setContextMenu,
} = workflowStudioSlice.actions;

export const bottombarVisible = (state: RootState) =>
  state.workflowStudioReducer.bottombarVisible;

export const previewNode = (state: RootState) =>
  state.workflowStudioReducer.currentPreviewNode;

// < ----- Flow Store ---- >
export const showFlowStore = (state: RootState) =>
  state.workflowStudioReducer.showFlowStore;

export const currentFlowItem = (state: RootState) =>
  state.workflowStudioReducer.currentFlowStoreItem;

// < ----- LLM Config ---- >
export const showLLMConfigPanel = (state: RootState) =>
  state.workflowStudioReducer.llmConfigPanel;

export const selectPanels = (state: RootState) => state.workflowStudioReducer;

export const selectPanel = (panelName: WORKFLOW_PANELS) =>
  createSelector([selectPanels], (panels) => panels[panelName]);

export const triggerRunButton = (state: RootState) =>
  state.workflowStudioReducer.triggerRunButton;

export const workflowContextMenu = (state: RootState) =>
  state.workflowStudioReducer.contextMenu;

export const workflowStudioReducer = workflowStudioSlice.reducer;
