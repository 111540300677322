import { Flex, Icon, ModalCloseButton, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { FaFileExport } from "react-icons/fa";
import { MdsFileUploadOffRound } from "react-icons-with-materialsymbols/mds";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { IExportRecord } from "../types";

const ExportErrorModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { exportRecord } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const message = useMemo(() => {
    return Array.isArray(exportRecord?.statusMsg)
      ? exportRecord?.statusMsg.join(".\n")
      : exportRecord?.statusMsg;
  }, [exportRecord]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      isCentered
      size="xl"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className="!p-0 rounded overflow-hidden">
        <ModalBody className="flex justify-end !p-5 gap-5">
          <Flex className="flex-col w-full grow gap-4">
            <Flex className="justify-between items-center w-full">
              <Text className="text-xl font-semibold grow">
                Data Export Failed
              </Text>
              <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
            </Flex>
            <Flex className="flex-col rounded gap-2 overflow-hidden border border-red-500 text-red-600 p-3 bg-red-50">
              <Flex className="items-center gap-2">
                <Icon
                  className="text-red-600"
                  as={FaFileExport}
                  fontSize={24}
                />
                <Text className="font-medium max-w-full overflow-hidden whitespace-pre text-ellipsis">
                  {(exportRecord as IExportRecord).sourceDatasetName}
                </Text>
              </Flex>
              <Text className="text-red-800">{message}</Text>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExportErrorModal;
