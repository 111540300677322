import { Button, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ToastType, useShowToast } from "@/components/toast";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { useCancelExportMutation } from "../api";
import { IExportRecord } from "../types";

const CancelExportModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const {
    exportRecord,
    analysisId,
    onClose: onModalClose,
  } = useAppSelector(
    (state) =>
      state.rootReducer.modals.modalProps as {
        exportRecord: IExportRecord;
        analysisId: string;
        onClose: () => void;
      }
  );
  const [cancelExport, { isLoading: isCancelling }] = useCancelExportMutation();

  const datasetName = useMemo(() => {
    return exportRecord.sourceDatasetName;
  }, [exportRecord]);

  const onClose = () => {
    if (isCancelling) return;
    dispatch(closeModal());
  };

  const cancelFileUpload = async () => {
    try {
      await cancelExport({
        analysisId,
        exportId: exportRecord.datasetOperationId,
      }).unwrap();
      toast({
        title: "Export cancelled",
      });
      if (onModalClose) {
        onModalClose();
      }
    } catch (error) {
      toast({
        title: "Failed to cancel export",
        status: ToastType.Error,
      });
    }
    dispatch(closeModal());
  };

  const onDelete = async () => {
    await cancelFileUpload();
  };

  return (
    <Modal
      isOpen={true}
      closeOnOverlayClick={false}
      isCentered
      size="lg"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="flex flex-col justify-end !px-5 gap-3">
          <Flex className="justify-between items-center w-full">
            <Text className="text-xl font-semibold grow">Cancel Export ?</Text>
            <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
          </Flex>
          <Text className="">
            Export for
            <span className="font-bold px-1">"{datasetName}"</span>
            will be cancelled. Are you sure you would like to proceed ?
          </Text>
          <Flex className={"justify-between w-full mt-7"}>
            <Button
              mr={2}
              colorScheme="secondary"
              isDisabled={isCancelling}
              onClick={onClose}
              variant="ghost"
            >
              Close
            </Button>
            <Button
              colorScheme="customRed"
              isDisabled={isCancelling}
              isLoading={isCancelling}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onDelete}
            >
              Cancel
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancelExportModal;
