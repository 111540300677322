import { Flex, Text } from "@chakra-ui/react";
import { MdOutlineCloud } from "react-icons/md";
import { SlRefresh } from "react-icons/sl";

import { Icon } from "@/design/components/icon";
import { isAutoSaving } from "@/features/workflow-studio/redux";
import { useAppSelector } from "@/reduxHooks.ts";

export const SaveStatus = () => {
  const isSaving = useAppSelector(isAutoSaving);

  return (
    <Flex className="p-3" align="center" gap={2}>
      {isSaving ? (
        <Icon as={SlRefresh} className="animate-spin" size="sm" />
      ) : (
        <Icon as={MdOutlineCloud} size="sm" />
      )}

      <Text fontSize="xs">{isSaving ? "Saving..." : "Saved"}</Text>
    </Flex>
  );
};
