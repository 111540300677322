import { useCallback } from "react";
import { getRectOfNodes, useReactFlow, Node } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks";

import { triggerAutoSave } from "../redux";
import { FlowSchema, NodeType } from "../types";
import { WorkflowNodeRelationsSchema } from "../types/workflow-types";
import { createCopiedNodeName } from "../utils/flow-utils";
import { createReactflowNodesFromFlow } from "../utils/transform-response";

export const useCreateFlowNodes = () => {
  const {
    getNodes,
    addNodes,
    fitView,
    setNodes,
    getEdges,
    setEdges,
    fitBounds,
  } = useReactFlow();

  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useAppDispatch();
  const createFlow = useCallback(
    (node: FlowSchema) => {
      try {
        if (!node) return;
        const flowData = node;
        const allNodes = getNodes();
        const allEdges = getEdges();
        const { parentNode, nodesinFlow, edgesInFlow } =
          createReactflowNodesFromFlow(flowData);
        const rectOfNodes = getRectOfNodes(getNodes());

        const updatedNodes = nodesinFlow.map((n) => ({
          ...n,
          data: {
            ...n.data,
            outputName: n.data.isOutput
              ? createCopiedNodeName(n, allNodes)
              : n.data.outputName,
          } as NodeType,
        }));

        const rectOfFlowNodes = getRectOfNodes(nodesinFlow);

        const updateParentNode = {
          ...parentNode,
          position: {
            x: rectOfNodes.x,
            y: rectOfNodes.y + rectOfNodes.height + 100,
          },
        };
        setNodes(
          [updateParentNode, ...updatedNodes, ...allNodes].sort((a, b) =>
            a.type === "group-node" ? -1 : 1
          )
        );
        setTimeout(() => {
          setEdges([...edgesInFlow, ...allEdges]);
          fitBounds(
            {
              x: rectOfNodes.x,
              y: rectOfNodes.y + rectOfNodes.height + 100,
              width: rectOfFlowNodes.width + 100,
              height: rectOfFlowNodes.height + 100,
            },
            { duration: 200, padding: 0.4 }
          );
          dispatch(triggerAutoSave());
        }, 100);
      } catch (e) {
        toast({
          title: "Error occured while adding flow",
          status: "error",
        });
        console.log(e);
      }
    },
    [getNodes, addNodes, fitView]
  );

  const addFlow = (flowNode: FlowSchema) => {
    if (!flowNode) return;
    createFlow(flowNode);
  };

  const duplicateFlow = (flowNode: Node) => {
    if (!flowNode) return;

    const flowData = flowNode.data as FlowSchema;
    // means flow is newly created and not yet saved to workflow

    const newFlowId = uuidv4();

    const nodesInFlow = getNodes().filter((n) => n.parentNode === flowNode.id);
    const nodesInFlowIds = nodesInFlow.map((n) => n.id);
    const newIdVsOldIdMap = new Map<string, string>();

    const updatedNodes = nodesInFlow.map((n) => {
      const newId = uuidv4();
      newIdVsOldIdMap.set(n.id, newId);
      return {
        ...n,
        id: newId,
        parentNode: newFlowId,
        data: {
          ...n.data,
          workflowNodeId: null,
          outputName: n.data.isOutput
            ? createCopiedNodeName(n, getNodes())
            : n.data.outputName,
        } as NodeType,
      };
    });

    const edgesInFlow = getEdges().filter((e) => {
      return (
        nodesInFlowIds.includes(e.source) && nodesInFlowIds.includes(e.target)
      );
    });

    const updatedEdges = edgesInFlow.map((e) => ({
      ...e,
      id: uuidv4(),
      source: newIdVsOldIdMap.get(e.source)!,
      target: newIdVsOldIdMap.get(e.target)!,
      data: {
        relationType: e.data.relationType,
        sourceUiNodeId: newIdVsOldIdMap.get(e.source),
        targetUiNodeId: newIdVsOldIdMap.get(e.target),
        sourceNodeIoId: e.data.sourceNodeIoId,
        targetNodeIoId: e.data.targetNodeIoId,
      } as WorkflowNodeRelationsSchema,
    }));
    const rectOfNodes = getRectOfNodes(getNodes());
    const rectOfFlowNodes = getRectOfNodes(updatedNodes);
    const parentNode: Node = {
      ...flowNode,
      id: newFlowId,
      position: {
        x: rectOfNodes.x,
        y: rectOfNodes.y + rectOfNodes.height + 100,
      },
      data: { ...flowData, workflowNodeId: null },
      type: "group-node",
      selected: false,
      expandParent: false,
    };

    setNodes(
      [parentNode, ...updatedNodes, ...getNodes()].sort((a, b) =>
        a.type === "group-node" ? -1 : 1
      )
    );
    setTimeout(() => {
      setEdges([...updatedEdges, ...getEdges()]);
      fitBounds(
        {
          x: rectOfNodes.x,
          y: rectOfNodes.y + rectOfNodes.height + 100,
          width: rectOfFlowNodes.width + 100,
          height: rectOfFlowNodes.height + 100,
        },
        { duration: 200, padding: 0.4 }
      );
    }, 100);
  };
  return { duplicateFlow, addFlow };
};
