import { useCallback } from "react";
import { getRectOfNodes, useReactFlow, Node } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { ToastType, useShowToast } from "@/components/toast";

import { NodeParameter, NodeType } from "../types";
import { NODE_STATUS } from "../utils/constants";
import { createCopiedNodeName } from "../utils/flow-utils";

export const useCreateCopyNode = () => {
  const { getNodes, addNodes, fitView } = useReactFlow();
  const toast = useShowToast(undefined, undefined, true);

  const duplicateNode = useCallback(
    (node: Node) => {
      try {
        if (!node) {
          throw new Error("Node not found");
        }
        const flowOutline = getRectOfNodes(getNodes());
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { workflowNodeId, ...resetData } = {
          ...node.data,
          nodeStatus: NODE_STATUS.DEFAULT,
          workflowNodeId: null,
          parameters: node.data.parameters.map((param: NodeParameter) => ({
            ...param,
            // value: null,
            errors: [],
          })),
        } as NodeType;

        addNodes([
          {
            ...node,
            id: uuidv4(),
            selected: false,
            parentNode: undefined,
            extent: undefined,
            position: {
              x: flowOutline.x + 50,
              y: flowOutline.y + flowOutline.height + 50,
            },
            data: {
              ...resetData,
              outputName: createCopiedNodeName(node, getNodes()),
            },
          },
        ]);
        setTimeout(() => {
          fitView({ duration: 300 });
        }, 100);
      } catch (error) {
        console.error(error);
        toast({
          description: "Failed to duplicate node",
          status: ToastType.Error,
        });
      }
    },
    [getNodes, addNodes, fitView]
  );

  return { createNodeCopy: duplicateNode };
};
