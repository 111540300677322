import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { useDeleteDestinationActorMutation } from "../api/data-query";

export const ActorDeleteModal = () => {
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);
  const { actorName, analysisId, actorId, onSuccess } = modalProps;
  const dispatch = useAppDispatch();
  const toast = useShowToast();
  const [deleteMutation, { isLoading }] = useDeleteDestinationActorMutation();

  const onClose = () => {
    dispatch(closeModal());
  };

  const handleConfirm = () => {
    deleteMutation({
      analysisId,
      actorId,
    })
      .unwrap()
      .then(() => {
        onSuccess?.();
        toast({
          title: "Configuration removed successfully",
          status: ToastType.Success,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Failed to remove configuration",
          status: ToastType.Error,
        });
      });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={true}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="flex flex-col justify-end !px-5 gap-3">
          <Flex className="justify-between items-start gap-4 w-full">
            <Text className="text-xl font-semibold grow break-all">
              Delete Configuration ?
            </Text>
            <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
          </Flex>
          <Text>
            Are you sure you want to delete the configuration "{actorName}"?
          </Text>
          <Flex className={"justify-between w-full mt-7"}>
            <Button
              colorScheme="dark"
              isDisabled={isLoading}
              onClick={onClose}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              colorScheme="customRed"
              isLoading={isLoading}
              onClick={handleConfirm}
              variant="solid"
            >
              Delete
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
