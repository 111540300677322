import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks";

import { useRunWorkflowMutation } from "../api/workflow-api";
import { hideAllPanels } from "../redux";
import {
  setEditingAllowed,
  setWorkflowRunId,
  setWorkflowRunStatus,
} from "../redux/workflow-slice";
import { WorkflowNodeSchema } from "../types/workflow-types";
import { NODE_STATUS } from "../utils/constants";
import { updateNodeIdAfterRun } from "../utils/index";
import { ConvertFlowtoWorkflowPayload } from "../utils/transform-response";

export const useRunWorkflow = () => {
  const dispatch = useAppDispatch();
  const { toObject, setNodes } = useReactFlow();
  const params = useParams();
  const toast = useShowToast();
  const [runWorkflow, { isLoading }] = useRunWorkflowMutation();

  const handleRunWorkflow = async (
    workflowId: string,
    { latestNodes }: { latestNodes?: WorkflowNodeSchema[] } = {}
  ) => {
    const obj = toObject();
    dispatch(setEditingAllowed(false));
    dispatch(hideAllPanels());
    const nodes = toObject().nodes;
    nodes.forEach((node) => {
      node.selected = false; // Deselect each node
    });
    setNodes(nodes); // Update the nodes in the React Flow state

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const res = await runWorkflow({
        analysisId: params.analysisId!,
        workflowId: workflowId,
        workflow: ConvertFlowtoWorkflowPayload(obj, latestNodes),
      }).unwrap();

      const wfStatus = res.response.data?.workflows[0];
      if (wfStatus?.workflowStatus === NODE_STATUS.RUNNING) {
        updateNodeIdAfterRun(wfStatus.workflowNodes, setNodes);
        toast({
          title: "Workflow run initiated successfully",
          status: "success",
        });
        dispatch(setWorkflowRunStatus(wfStatus.workflowStatus));
        dispatch(setWorkflowRunId(wfStatus.workflowRunId as string));
      }
    } catch (err) {
      dispatch(setEditingAllowed(true));
      dispatch(setWorkflowRunStatus(NODE_STATUS.DEFAULT));
      console.log(err);
    }
  };

  return {
    runWorkflow: handleRunWorkflow,
    isLoading,
  };
};
