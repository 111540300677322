import { Box, CloseButton, Flex, Image } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

import {
  previewNode,
  setPreviewNode,
} from "@/features/workflow-studio/redux/workflow-studio-slice";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { categoryIcons } from "../../utils/constants";

export const NodeInfoPreview = () => {
  const currentNode = useAppSelector(previewNode);
  const dispatch = useAppDispatch();

  const nodeInfoPreviewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!nodeInfoPreviewRef.current?.contains(event.target as Node)) {
        dispatch(setPreviewNode(null));
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch]);

  if (!currentNode) return null;

  const onClose = () => {
    dispatch(setPreviewNode(null));
  };

  return (
    <Flex
      className="w-[20vw] z-[10] absolute bottom-5 right-5 text-white rounded p-3"
      ref={nodeInfoPreviewRef}
      direction="column"
      gap={"10px"}
      bg="gray.800"
    >
      <Flex align="center" gap={"10px"}>
        <Box bg={categoryIcons[currentNode.nodeCategory].theme} rounded={"sm"}>
          <Image
            boxSize="2rem"
            p={1.5}
            bg={categoryIcons[currentNode.nodeCategory].theme}
            alt="Fluffybuns the destroyer"
            draggable={false}
            rounded="sm"
            src={
              currentNode.imageUrl ??
              "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Globe_icon-white.svg/2048px-Globe_icon-white.svg.png"
            }
          />
        </Box>
        <div className="flex-1">{currentNode.displayName}</div>
        <CloseButton onClick={onClose} />
      </Flex>
      <small>{currentNode.description}</small>
      {/* <a href="#" className="text-xs text-right">
        <span className="underline">Learn More</span>
        &nbsp;&#8599;
      </a> */}
    </Flex>
  );
};

export default NodeInfoPreview;
