import {
  Menu,
  MenuList,
  MenuItem,
  Icon,
  Tooltip,
  PlacementWithLogical,
} from "@chakra-ui/react";
import React, { useRef, useLayoutEffect } from "react";
import { IconType } from "react-icons";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { hideContextMenu } from "@/slices/context-menu-slice";

import { ProtectedComponent } from "../protected-component/protected-component";

const ContextMenu: React.FC = () => {
  const { x, y, items, show } = useAppSelector(
    (state) => state.rootReducer.contextMenu
  );
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState({
    menuX: x + 10,
    menuY: y + 10,
  });

  useLayoutEffect(() => {
    if (menuRef.current) {
      const menuWidth = 200;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const menuHeight = menuRef.current.getBoundingClientRect().height;

      const menuX = x + (menuWidth > windowWidth - x ? -menuWidth : 10);
      const menuY = y + (menuHeight > windowHeight - y ? -menuHeight : 10);

      setPosition({ menuX, menuY });
    }
  }, [x, y, show]);

  if (!show) return null;

  const onRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleItemClick = (action: any) => {
    if (action) {
      action();
    }
    dispatch(hideContextMenu());
  };

  return (
    <Menu isOpen={true}>
      <MenuList
        ref={menuRef}
        pos="fixed"
        zIndex={100}
        top={`${position.menuY}px`}
        left={`${position.menuX}px`}
        minW="200px"
        bg="white"
        borderRadius="md"
        shadow="md"
      >
        {items
          .filter((item) => item.isVisible !== false)
          .map((item, index) => {
            const { extraOptions, isProtected, isDisabled } = item;
            if (isProtected) {
              return (
                <ProtectedComponent
                  key={"menuItem" + index}
                  requiredPermission={extraOptions.permission}
                  id={extraOptions.data.id}
                  type={extraOptions.data.type}
                >
                  <Tooltip
                    hasArrow={item?.toolTip?.hasArrow ?? false}
                    isDisabled={!item?.toolTip?.label}
                    label={item?.toolTip?.label}
                    placement={
                      (item?.toolTip?.placement as PlacementWithLogical) ??
                      "top"
                    }
                  >
                    <MenuItem
                      color={item.color ? item.color : ""}
                      _hover={{ bg: "gray.100" }}
                      icon={<Icon as={item?.icon as IconType} fontSize={20} />}
                      isDisabled={isDisabled}
                      onClick={() => handleItemClick(item.action)}
                      onContextMenu={onRightClick}
                    >
                      {item.label}
                    </MenuItem>
                  </Tooltip>
                </ProtectedComponent>
              );
            }
            return (
              <Tooltip
                key={"menuItem-tt-" + index}
                hasArrow={item?.toolTip?.hasArrow ?? false}
                isDisabled={!item?.toolTip?.label}
                label={item?.toolTip?.label}
                placement={
                  (item?.toolTip?.placement as PlacementWithLogical) ?? "top"
                }
              >
                <MenuItem
                  key={"menuItem" + index}
                  color={item.color ? item.color : ""}
                  _hover={{ bg: "gray.100" }}
                  icon={<Icon as={item?.icon as IconType} fontSize={20} />}
                  isDisabled={isDisabled}
                  onClick={() => handleItemClick(item.action)}
                  onContextMenu={onRightClick}
                >
                  {item.label}
                </MenuItem>
              </Tooltip>
            );
          })}
      </MenuList>
    </Menu>
  );
};

export default ContextMenu;
