export enum CATEGORY {
  Workspace = "workspace",
  Project = "project",
  Analysis = "analysis",
  Dashboard = "dashboard",
}

export enum PERMISSIONS {
  READ = "read",
  WRITE = "edit",
  DELETE = "delete",
  CREATE = "create",
  MANAGE_ACCESS = "manage_access",
}
export function categoryToType(value: CATEGORY): string {
  switch (value) {
    case CATEGORY.Workspace:
      return "Workspaces";
    case CATEGORY.Analysis:
      return "Analysis";
    case CATEGORY.Project:
      return "Project";
    case CATEGORY.Dashboard:
      return "Dashboard";
    default:
      throw new Error(`Invalid CATEGORY value`);
  }
}

export const enum DOWNLOAD_STATES {
  // "not_available/pending/in_progress/complete/failed",
  NOT_AVAILABLE = "not_available",
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  FAILED = "failed",
}

export const enum STATUS {
  SUCCESS = "success",
  FAIL = "error",
}

export const enum EDA_STATE {
  NOT_AVAILABLE = "not_available",
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  FAILED = "failed",
}

export const DATASET_RUN_STATUS_VALUES = {
  [EDA_STATE.NOT_AVAILABLE]: "Ready",
  [EDA_STATE.PENDING]: "Execution Pending",
  [EDA_STATE.IN_PROGRESS]: "Executing...",
  [EDA_STATE.COMPLETE]: "Ready",
  [EDA_STATE.FAILED]: "Execution Failed",
};

export const enum DATA_EXPORT_STATUS {
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  FAILED = "failed",
}

export const DATA_EXPORT_STATUS_VALUES = {
  [DATA_EXPORT_STATUS.IN_PROGRESS]: "Exporting",
  [DATA_EXPORT_STATUS.COMPLETE]: "Completed",
  [DATA_EXPORT_STATUS.FAILED]: "Failed",
};

export const enum DATA_UPLOAD_STATUS {
  FAIL = "failed",
  UPLOADING_TO_BRONZE_ZONE = "uploading_to_bronze_zone",
  REVIEW_PENDING = "review_pending",
  UPLOADING_TO_CS_ZONE = "uploading_to_cs_zone",
  NOT_STARTED = "not_started",
  SELECTION_PENDING = "sheet_selection_pending",
  REVIEWED = "reviewed",
}

const dataUploadStatusValues = {
  [DATA_UPLOAD_STATUS.FAIL]: "Failed",
  [DATA_UPLOAD_STATUS.UPLOADING_TO_BRONZE_ZONE]: "Processing", // Adjust based on preferred text
  [DATA_UPLOAD_STATUS.REVIEW_PENDING]: "Review Pending",
  [DATA_UPLOAD_STATUS.UPLOADING_TO_CS_ZONE]: "Uploading",
  [DATA_UPLOAD_STATUS.NOT_STARTED]: "Queued",
  [DATA_UPLOAD_STATUS.SELECTION_PENDING]: "Selection Pending",
};

export function getExportStatusValue(status: DATA_EXPORT_STATUS): string {
  return DATA_EXPORT_STATUS_VALUES[status];
}

export function getDataSetStatusValue(status: EDA_STATE): string {
  return DATASET_RUN_STATUS_VALUES[status];
}

export function getDataUploadStatusValue(status: DATA_UPLOAD_STATUS): string {
  return dataUploadStatusValues[status];
}

export function getActivityTypeValue(type: ACTIVITY_TYPE): string {
  return activityTypeValues[type];
}

export const enum ACTIVITY_TYPE {
  NEW_UPLOAD = "new_upload",
  REPLACE_DATA = "replace_data",
  OUTPUT_DATA = "output_data",
}

const activityTypeValues = {
  [ACTIVITY_TYPE.NEW_UPLOAD]: "New Upload",
  [ACTIVITY_TYPE.REPLACE_DATA]: "Replace Data",
  [ACTIVITY_TYPE.OUTPUT_DATA]: "Output Data",
};

export const enum ACTION_TYPE {
  MARK_FAVORITE = "mark_favorite",
  UNMARK_FAVORITE = "unmark_favorite",
}

export const enum DELETE_TYPE {
  CASCADE = "cascade_delete",
  INDEPENDENT = "independent_delete",
}

export const enum ADD_DATASET_TAGS {
  FILE = "file",
  AUTH_REQUIRED = "authentication required",
}

export const enum PRIVACY_STATUS {
  PUBLIC = "public",
  PRIVATE = "private",
}

export const enum VALIDATION_TYPES {
  DUPLICATE_TITLE = "duplicate_title",
  DATASET_TITLE = "dataset_title",
  ACTOR_TITLE = "actor_title",
  DATASET_USAGE = "dataset_usage",
}

export const enum VALIDATION_STATUS {
  FAILED = "Failed",
  SUCCESS = "Success",
}

export const enum ACCESS_LEVEL {
  VIEWER = "Viewer",
  EDITOR = "Editor",
  ADMIN = "Admin",
  // manager is similar to editor but with additional permissions
  MANAGER = "Manager",
}

export const enum MICRO_SERVICES {
  NODE_MANAGER = "node-mgmt",
  WORKFLOW_MANAGER = "workflow-mgmt",
  WORKSPACE_MANAGER = "workspace-mgmt",
  DATA_MANAGER = "data-mgmt",
  PDS = "pds-mgmt",
  USER_MANAGER = "user-mgmt",
  AI_PLAYGROUND = "playground-mgmt",
  EDA = "eda-mgmt",
  INSIGHTS = "insights-mgmt",
}

export const enum POLLING_STATUS {
  COMPLETED = "completed",
  FAILED = "failed",
  IN_PROGRESS = "in_progress",
}

export const enum RUN_STATUS {
  RUNNING = "running",
  COMPLETED = "completed",
  FAILED = "failed",
}

export const enum SOURCE_FILE_TYPE {
  LOCAL_FILE = "local_file_upload",
  AIRBYTE = "airbyte",
}

export enum DATASET_FORMAT {
  CSV = "csv",
  XLSX = "xlsx",
}

export enum DATA_EXPORT_CATEGORY {
  RAW = "raw_download",
  TRANSFORMED = "transformed_download",
}

export enum DATASET_USAGE {
  EDA = "EDA validation failed",
  INSIGHTS = "Insights validation failed",
  WORKFLOW = "Workflow validation failed",
  EXPORT_STATUS = "Export Status validation failed",
}

export enum USER_ROLES {
  ADMIN = "org_admin",
  CTK_ADMIN = "clootrack_admin",
  USER = "user",
}
