import { Box, Flex } from "@chakra-ui/react";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NodeProps, useKeyPress, useReactFlow } from "reactflow";

import {
  getEditingAllowed,
  hideAllPanels,
  hidePanel,
  selectPanel,
  setContextMenu,
  showPanel,
  toggleLLMConfigPanel,
} from "@/features/workflow-studio/redux";
import { UNIQUE_NODES } from "@/features/workflow-studio/utils";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { useShowTransformPanel } from "../../hooks";
import { NodeType } from "../../types";
import {
  NODE_STATUS,
  NODE_TYPES,
  WORKFLOW_PANELS,
} from "../../utils/constants";

import { NodeErrors } from "./node-errors";
import { NodeHandles } from "./node-handles";
import { NodeMetaData } from "./node-metaData";
import { NodeOutputTag } from "./node-output-tag";
import DataNodeToolbar from "./node-toolbar";

export const DataNode = ({
  id,
  data,
  isConnectable,
  selected,
}: NodeProps<NodeType>) => {
  // boolean state variable to set nodeToolbar to visible on hover
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  );
  const dataPreviewPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataPreviewPanel)
  );

  const isWFEditAllowed = useAppSelector(getEditingAllowed);

  const dispatch = useAppDispatch();

  const { deleteElements } = useReactFlow();

  const { showTransformPanel } = useShowTransformPanel();
  const deletePressed = useKeyPress("Backspace");

  const hasConfig = useMemo(
    () => data.parameters.length > 0,
    [data.parameters]
  );

  // TODO: Need to change this later, need a way to indentify unique nodes
  const isTransformNode = useMemo(
    () => data.displayName === UNIQUE_NODES.TRANSFORM,
    [data.displayName]
  );

  const isLLMNode = useMemo(
    () => data.displayName === UNIQUE_NODES.LLM,
    [data.displayName]
  );

  const showToolbar = useMemo(() => {
    return isHovered && isWFEditAllowed;
  }, [isHovered, isWFEditAllowed]);

  const onNodeClick = useCallback(async () => {
    dispatch(setContextMenu(null));
    if (!selected) return;

    // Skip opening config panel for source nodes
    if (data.nodeType === NODE_TYPES.SOURCE_NODE) return;

    if (!isTransformNode && !isWFEditAllowed) {
      // allow only UNIQUE_NODES to open when editing is not allowed
      return;
    }

    if (isLLMNode) {
      dispatch(hidePanel(WORKFLOW_PANELS.DataPreviewPanel));
      dispatch(toggleLLMConfigPanel({ show: true, nodeId: id }));
      return;
    }
    if (!hasConfig) {
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
      return;
    }

    if (isTransformNode) {
      setIsLoading(true);
      await showTransformPanel({ transformNodeUiId: id });
      setIsLoading(false);
      return;
    }

    dispatch(
      showPanel({
        panel: WORKFLOW_PANELS.NodeConfigurationPanel,
        nodeId: id,
      })
    );
  }, [
    selected,
    dispatch,
    id,
    hasConfig,
    isTransformNode,
    isLLMNode,
    isWFEditAllowed,
    showTransformPanel,
  ]);

  // if the current node is selected and the delete key is pressed, delete the node
  useEffect(() => {
    const shouldDeleteNode = selected && deletePressed;

    if (shouldDeleteNode) {
      const isCurrentNodeConfigOpen =
        nodeConfigPanel.nodeId === id || dataPreviewPanel.nodeId === id;
      if (isCurrentNodeConfigOpen) dispatch(hideAllPanels());
      deleteElements({ nodes: [{ id }] });
    }
  }, [
    selected,
    deletePressed,
    deleteElements,
    dispatch,
    dataPreviewPanel,
    nodeConfigPanel,
    id,
  ]);

  const onDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (nodeConfigPanel.nodeId === id || dataPreviewPanel.nodeId === id)
      dispatch(hideAllPanels());
    deleteElements({ nodes: [{ id }] });
  };

  return (
    <Box
      className={clsx(
        "relative group/groupCard !shadow-noblur",
        "rounded bg-white text-gray-700 border border-gray-700",
        selected && "border-2"
      )}
      w={"160px"}
      maxW={"200px"}
      minH={"120px"}
      maxH="160px"
      onClick={onNodeClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showToolbar && <DataNodeToolbar onDelete={onDelete} id={id} />}
      <NodeHandles isConnectable={isConnectable} />
      <Flex className={clsx(`w-full gap-3 p-3`)} direction="column">
        <NodeMetaData id={id} nodeData={data} isLoading={isLoading} />
        {data.isOutput && (
          <NodeOutputTag
            title={data.outputName}
            status={data.outputState as NODE_STATUS}
          />
        )}
      </Flex>
      <NodeErrors id={id} />
    </Box>
  );
};

export default DataNode;
