import { MdOutlineCable, MdOutlineDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  useNodes,
  Node,
  NodeToolbar,
  useStoreApi,
  getRectOfNodes,
  useReactFlow,
  Position,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { triggerAutoSave } from "@/features/workflow-studio/redux";
import { PRIVACY_STATUS } from "@/utils/enums";

import { validateNodeforGrouping } from "../../utils/validations";

const padding = 25;

export default function MultiSelectNodeToolbar() {
  const nodes = useNodes();
  const dispatch = useDispatch();
  const { setNodes, deleteElements } = useReactFlow();
  const toast = useShowToast(undefined, undefined, true);

  const store = useStoreApi();
  const selectedNodes = nodes.filter(
    (node) => node.selected && !node.parentNode
  );
  const selectedNodeIds = selectedNodes.map((node) => node.id);
  const isVisible = selectedNodeIds.length > 1;

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const onGroup = () => {
    const groupingError = validateNodeforGrouping(selectedNodes);
    if (groupingError !== "") {
      toast({
        description: groupingError,
        variant: "left-accent",
        status: "warning",
      });
      return;
    }
    const rectOfNodes = getRectOfNodes(selectedNodes);
    // const groupId = "db0ea395-de9a-4085-9d2f-ad35f3dfe82012";//uuidv4();
    const groupId = uuidv4();
    const parentPosition = {
      x: rectOfNodes.x,
      y: rectOfNodes.y,
    };
    const groupNode = {
      id: groupId,
      type: "group-node",
      position: parentPosition,
      style: {
        width: rectOfNodes.width + padding * 2,
        height: rectOfNodes.height + padding * 2,
      },
      data: {
        displayName: "Untitled Flow",
        displayText: "Untitled Flow",
        description: null,
        clientId: null,
        userId: null,
        nodeType: "CUSTOM_FLOW_NODE",
        isFavorite: false,
        uploadDate: new Date().toLocaleString(),
        lastUsedAt: new Date().toLocaleString(),
        tags: [],
        privacyStatus: PRIVACY_STATUS.PUBLIC,
      },
    };

    const nextNodes: Node[] = nodes.map((node) => {
      if (selectedNodeIds.includes(node.id)) {
        return {
          ...node,
          position: {
            x: node.position.x - parentPosition.x + padding,
            y: node.position.y - parentPosition.y + padding,
          },
          selected: false,
          extent: "parent",
          parentNode: groupId,
        };
      }

      return node;
    });

    store.getState().resetSelectedElements();
    store.setState({ nodesSelectionActive: false });
    setNodes([groupNode, ...nextNodes]);
    autoSave();
  };

  const removeNodes = () => {
    deleteElements({ nodes: selectedNodeIds.map((id) => ({ id })) });
    store.getState().resetSelectedElements();
    store.setState({ nodesSelectionActive: false });
    // autoSave will be called by onNodeDelete in reactflow
  };

  return (
    <NodeToolbar
      position={Position.Bottom}
      className="bg-white p-1 flex flex-col items-start border border-gray-400"
      nodeId={selectedNodeIds}
      isVisible={isVisible}
    >
      <Button
        variant="ghost"
        className="!p-1"
        size="sm"
        colorScheme="dark"
        leftIcon={<MdOutlineCable className="rotate-90" />}
        onClick={onGroup}
      >
        Group selected nodes
      </Button>
      <hr className="border-black" />
      <Button
        variant="ghost"
        className="!p-1 w-full !justify-start"
        size="sm"
        colorScheme="red"
        leftIcon={<MdOutlineDelete />}
        onClick={removeNodes}
      >
        Remove nodes
      </Button>
    </NodeToolbar>
  );
}

// const MultiSelectNodeToolbar = () => {
//   return (
//     <div className="absolute -z-10 -top-8 left-1/2 -translate-x-1/2 w-full px-3 h-full">
//       <NodeToolbar>
//         <Button variant="outline" colorScheme="orange">
//           Group selected nodes
//         </Button>
//       </NodeToolbar>
//     </div>
//   );
// };
