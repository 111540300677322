import { Input } from "@chakra-ui/input";
import { Divider, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { MdsDoneRound } from "react-icons-with-materialsymbols/mds";
import { useSelector } from "react-redux";

import { FormErrorIcon, FormErrorMessage } from "@/design/components/form";
import { Icon } from "@/design/components/icon";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemDescription,
  MenuList,
} from "@/design/components/menu";
import {
  FULL_DATA_SAMPLING,
  ISamplingTechnique,
  ITableDataColumns,
  NUMBER_OF_RECORDS_SHORT_NAME,
  START_PARAMETER_SHORT_NAME,
  STRATIFIED_SAMPLING,
  tableColumns,
} from "@/features/data-transformation";
import { useOldColumns } from "@/features/data-transformation/components/filter-row/use-old-columns.ts";

import { FilterSubmenuContext } from "./filter-submenu-context";

export const FilterDataSubmenu = () => {
  const {
    isOpen,
    sampling,
    selectedSamplingMethod,
    onSamplingChange,
    setNumberOfRows,
    numberOfRows,
    selectedColumn,
    setSelectedColumn,
    error,
    setError,
  } = useContext(FilterSubmenuContext)!;

  const tableCols = useSelector(tableColumns);

  const oldColumns = useOldColumns();

  const columns = useMemo(() => {
    try {
      const mappedOldCols: Partial<ITableDataColumns>[] | undefined =
        oldColumns?.map((col) => ({
          name: col,
        }));
      return mappedOldCols ?? tableCols;
    } catch (e) {
      return tableCols;
    }
  }, [tableCols, oldColumns]);

  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
  const [metaDataMenu, setMetaDataMenu] = useState<boolean>(false);

  useEffect(() => {
    const currentSampling = sampling?.currentSampling;
    if (currentSampling?.shortName === STRATIFIED_SAMPLING) {
      const params = currentSampling.parameters.find(
        (sample) => sample.shortName === START_PARAMETER_SHORT_NAME
      )!;
      setSelectedColumn({
        name: params.value,
      } as Partial<ITableDataColumns>);
    }
  }, [sampling]);

  const handleColumnChange = (column: Partial<ITableDataColumns>) => {
    setSelectedColumn(column);
    setMetaDataMenu(false);
    onSamplingChange(selectedSamplingMethod, numberOfRows ?? "", column);
  };

  const handleSamplingChange = (method: ISamplingTechnique) => {
    onSamplingChange(method, numberOfRows ?? "", selectedColumn);
    setSubmenuOpen(false);
    closeMetaDataMenu();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    // Reset other states when input changes
    setError("");
    setSubmenuOpen(false);
    closeMetaDataMenu();
    setNumberOfRows(value);
  };

  const samplingTechniques =
    sampling?.samplingTechniques?.filter(
      (technique) => technique.shortName !== FULL_DATA_SAMPLING
    ) ?? [];

  const closeSubmenu = () => {
    if (submenuOpen) {
      setSubmenuOpen(false);
    }
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
    closeMetaDataMenu();
  };

  const toggleMetaDataMenu = () => {
    setMetaDataMenu(!metaDataMenu);
  };

  const closeMetaDataMenu = () => {
    if (metaDataMenu) {
      setMetaDataMenu(false);
    }
  };

  const stratifiedSelected =
    selectedSamplingMethod?.shortName === STRATIFIED_SAMPLING;
  const isLoadFullData =
    selectedSamplingMethod?.shortName === FULL_DATA_SAMPLING;

  const selectedSamplingParameters = selectedSamplingMethod?.parameters ?? [];
  const currentSamplingRecords = selectedSamplingParameters.find(
    (sample) => sample.shortName === NUMBER_OF_RECORDS_SHORT_NAME
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <Flex
          className="flex-col"
          as={motion.div}
          animate={{
            opacity: 1,
            height: "auto",
            transition: { ease: "easeInOut" },
          }}
          exit={{ opacity: 0, height: 0 }}
          initial={{ opacity: 1, height: "auto" }}
        >
          <Divider className="my-5" />
          <Flex>
            <FormControl isInvalid={!isEmpty(error)} size="md">
              <FormLabel className="!mb-1.5 !ml-1.5 text-sm leading-none">
                Number of rows (N)
              </FormLabel>
              <Input
                type="number"
                className="text-right font-medium"
                defaultValue={currentSamplingRecords?.value}
                onChange={handleInputChange}
                value={numberOfRows}
              />
              <FormErrorMessage className="pl-1 text-[13px]">
                <FormErrorIcon className="!self-start !mt-0.5" />
                {error}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex className="mt-5 flex-col gap-y-5">
            <FormControl>
              <FormLabel className="!mb-1.5 !ml-1.5 text-sm leading-none">
                Sampling Method
              </FormLabel>
              <Menu
                isOpen={submenuOpen}
                placement="bottom"
                onClose={closeSubmenu}
              >
                <MenuButton
                  as={Flex}
                  onClick={toggleSubmenu}
                  className="!cursor-pointer"
                >
                  <Flex className="flex flex-row w-full justify-between items-center border border-gray-300 rounded-md p-2.5 gap-1.5  select-none">
                    <Text className="text-[15px] font-medium tracking-tight truncate max-w-[10rem]">
                      {!isLoadFullData ? selectedSamplingMethod?.label : ""}
                    </Text>
                    <Icon as={TbChevronDown} size="md" color="gray.600" />
                  </Flex>
                </MenuButton>
                <MenuList className="-mt-2 w-[288px] max-h-[35vh] overflow-y-auto !z-10">
                  {samplingTechniques.map((method, index) => {
                    const isSelected =
                      selectedSamplingMethod?.shortName === method.shortName;
                    return (
                      <MenuItem
                        key={index}
                        hasDescription={true}
                        onClick={handleSamplingChange.bind(null, method)}
                      >
                        <Flex className="flex-col">
                          {method.label}
                          <MenuItemDescription className="mt-1">
                            {method.description}
                          </MenuItemDescription>
                        </Flex>
                        {isSelected && (
                          <Icon
                            as={MdsDoneRound}
                            size="sm"
                            className="stroke-[28]"
                            fontSize={20}
                            color="gray.900"
                          />
                        )}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </FormControl>
            {stratifiedSelected && (
              <FormControl>
                <FormLabel className="!mb-1.5 !ml-1.5 text-sm leading-none">
                  Column for Stratification
                </FormLabel>
                <Menu
                  isOpen={metaDataMenu}
                  placement="bottom"
                  onClose={closeMetaDataMenu}
                >
                  <MenuButton
                    as={Flex}
                    onClick={toggleMetaDataMenu}
                    className="!cursor-pointer"
                  >
                    <Flex className="flex flex-row w-full justify-between items-center border border-gray-300 rounded-md p-2.5 gap-1.5  select-none">
                      <Text className="text-[15px] font-medium tracking-tight truncate max-w-[10rem]">
                        {selectedColumn?.name}
                      </Text>
                      <Icon as={TbChevronDown} size="md" color="gray.600" />
                    </Flex>
                  </MenuButton>
                  <MenuList className="-mt-2 w-[288px] overflow-auto !max-h-[200px] h-[200px] !z-10">
                    {columns.map((column, index) => {
                      const isSelected = selectedColumn?.name === column.name;
                      return (
                        <MenuItem
                          key={index}
                          onClick={handleColumnChange.bind(null, column)}
                          className="relative"
                        >
                          {column.name}
                          {isSelected && (
                            <Icon
                              as={MdsDoneRound}
                              size="sm"
                              color="gray.900"
                              className="absolute right-2"
                            />
                          )}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </FormControl>
            )}
          </Flex>
        </Flex>
      )}
    </AnimatePresence>
  );
};
