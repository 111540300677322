import { useEffect, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Instance, Node } from "reactflow";

import { useSaveWorkflowMutation } from "@/features/workflow-studio";
import { useFlowLocalSave } from "@/features/workflow-studio/hooks/useSaveToLocal.ts";
import {
  AUTO_SAVE_DEBOUNCE,
  updateNodesAfterSave,
} from "@/features/workflow-studio/utils";
import { ConvertFlowtoWorkflowPayload } from "@/features/workflow-studio/utils/transform-response.ts";
import {
  useCancellableDebounce,
  useSingletonDebounce,
} from "@/hooks/useSingletonDebounce.ts";
import { useAppSelector } from "@/reduxHooks.ts";

import {
  isAutoSaving,
  resetTriggerAutoSave,
  setIsSaving,
  shouldTriggerAutoSave,
  timerInterval,
} from "../redux";

interface UseFlowAutoSaveHookReturn {
  isSaving: boolean;
  cancelAutoSave: () => void;
}

/**
 * Custom hook to auto-save the flow instance at a given interval
 * @param toObject - The Flow instance to save
 * @param setNodes - The interval (in seconds) at which to save the flow instance
 */
export const useFlowAutoSave = (
  toObject: Instance.ToObject,
  setNodes: (
    value: React.SetStateAction<Node<any, string | undefined>[]>
  ) => void
): UseFlowAutoSaveHookReturn => {
  const dispatch = useDispatch();

  const params = useParams();
  // const { toObject } = useReactFlow();
  const instance = toObject();

  // const instance = useAppSelector(currentFlowInstance);

  const interval = useAppSelector(timerInterval);
  const isSaving = useAppSelector(isAutoSaving);
  const triggerAutoSave = useAppSelector(shouldTriggerAutoSave);

  const timerRef = useRef(0);

  const [saveWorkflow] = useSaveWorkflowMutation();

  const intervalRef = useRef<number | null>(null);
  const [shouldRunInterval, setShouldRunInterval] = useState(true);

  const { editorId } = useParams();
  const { triggerLocalSave } = useFlowLocalSave(instance, editorId!);

  const saveWorkflowApi = async () => {
    try {
      const res = await saveWorkflow({
        analysisId: params.analysisId!,
        workflow: ConvertFlowtoWorkflowPayload(instance),
        workflowId: params.editorId!,
      }).unwrap();

      const newNodes = res.response.data!.workflows[0].workflowNodes;
      setNodes((currentNodes) => updateNodesAfterSave(newNodes, currentNodes));
    } catch (e) {
      console.error("Error saving workflow", e);
    }
  };

  const saveFlow = useCallback(async () => {
    dispatch(setIsSaving(true));

    // clearInterval(intervalRef.current!);
    // setShouldRunInterval(false);

    try {
      triggerLocalSave();
      await saveWorkflowApi();
    } catch (error) {
      console.error("Autosave error:", error);
      // timerRef.current = interval;
      // setShouldRunInterval(true);
    } finally {
      dispatch(setIsSaving(false));
      // timerRef.current = interval;
      // setShouldRunInterval(true);
    }
  }, [triggerLocalSave]);

  const autoSave = useSingletonDebounce(
    saveFlow,
    AUTO_SAVE_DEBOUNCE,
    "AUTOSAVE_DEBOUNCE"
  );

  useEffect(() => {
    if (!editorId) return;
    if (isSaving || !instance) return;

    if (triggerAutoSave) {
      saveFlow();
      dispatch(resetTriggerAutoSave());
    }
  }, [dispatch, editorId, saveFlow, triggerAutoSave, instance]);

  useEffect(() => {
    if (!editorId || !shouldRunInterval || !instance) return;

    const startAutoSaveTimer = () => {
      if (intervalRef.current) clearInterval(intervalRef.current);

      intervalRef.current = window.setTimeout(() => {
        autoSave();
      }, interval * 1000); // Convert seconds to milliseconds
    };

    // startAutoSaveTimer();

    // if (shouldRunInterval) {
    //   startAutoSaveTimer();
    // }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [
    editorId,
    autoSave,
    interval,
    shouldRunInterval,
    instance,
    triggerAutoSave,
  ]);

  const cancelAutoSave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    dispatch(setIsSaving(false)); // Reset isSaving state in store
  };

  return { isSaving, cancelAutoSave };
};
