import { Divider } from "@chakra-ui/layout";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import ExportConnectorsBody from "./export-connectors-body";

export const ExportConnectorsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };
  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent className="!max-w-[796px] w-full !pb-0 !gap-0">
        <ModalHeader>Choose Destination</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <Divider className="mt-4" />
        <ModalBody>
          <ExportConnectorsBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
