import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks.ts";
import { ModalTypes, openModal } from "@/slices/modal-slice";

import { useUnMarkOutputMutation } from "../api";
import { ConvertFlowtoWorkflowPayload } from "../utils/transform-response";

function useManageOutput() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { toObject, getNode, setNodes } = useReactFlow();
  const toast = useShowToast(undefined, undefined, true);
  const [unMarkOutput, { isLoading: isUnMarking }] = useUnMarkOutputMutation();

  const resetNodeOutput = (id: string) => {
    setNodes((prev) => {
      return prev.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              isOutput: false,
              outputName: null,
              outputState: null,
            },
          };
        }
        return node;
      });
    });
  };

  const removeOutput = async ({ id }: { id: string }) => {
    if (isUnMarking) return;
    const toastId = "unmark-output-toast";
    const currentNode = getNode(id);

    if (!currentNode) return;

    if (isEmpty(currentNode?.data.workflowNodeId)) {
      resetNodeOutput(id);
      return;
    }
    try {
      toast({
        id: toastId,
        status: ToastType.Loading,
        title: "Unmarking output",
      });
      const workflow = ConvertFlowtoWorkflowPayload(toObject());
      const updatedWF = {
        ...workflow,
        outputNodes:
          workflow.outputNodes?.filter((node) => node.uiNodeId !== id) ?? [],
        unmarkedOutputNodes: [...(workflow.unmarkedOutputNodes ?? []), id],
      };
      await unMarkOutput({
        analysisId: params.analysisId as string,
        workflowId: params.editorId as string,
        workflow: updatedWF,
      }).unwrap();
      resetNodeOutput(id);
      toast.update(toastId, {
        status: "success",
        title: "Output unmarked successfully",
      });
    } catch (e) {
      toast.update(toastId, {
        status: "error",
        title: "Failed to unmark output",
      });
    }
  };

  const openOutputModal = ({ id }: { id: string }) => {
    dispatch(
      openModal({
        modalType: ModalTypes.MARK_AS_OUTPUT,
        modalProps: {
          node: getNode(id),
          setNodes,
          instance: toObject(),
          params,
        },
      })
    );
  };

  return {
    isUnMarking,
    removeOutput,
    openOutputModal,
  };
}

export default useManageOutput;
