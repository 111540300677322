import { Box, chakra, Flex, Spinner } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { clsx } from "clsx";
import { debounce } from "lodash";
import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useMemo,
} from "react";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Node, useReactFlow } from "reactflow";

import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@/design/components/data-table";
import { selectPanel } from "@/features/workflow-studio";
import { useLazyGetNodeDataQuery } from "@/features/workflow-studio/api/node-data.ts";
import {
  currentReferenceRunId,
  currentWorkflowRunId,
  workflowRunningStatus,
} from "@/features/workflow-studio/redux/workflow-slice";
import { NodeType } from "@/features/workflow-studio/types";
import {
  NODE_STATUS,
  WORKFLOW_PANELS,
} from "@/features/workflow-studio/utils/constants.tsx";
import { useAppSelector } from "@/reduxHooks.ts";

interface DataPreviewTableProps {
  type: string;
  isExpanded: boolean;
  ioDetailId: string;
  tabIndex: number;
}
export const DataPreviewTable = React.forwardRef<
  HTMLDivElement,
  DataPreviewTableProps
>(({ isExpanded, type, ioDetailId, tabIndex }, ref) => {
  const page = useRef<number>(1);
  const isPaginating = useRef<boolean>(false);

  const { analysisId } = useParams<{
    analysisId: string;
  }>();

  const { getNode } = useReactFlow();
  const [fetchNodeData, { data, isFetching, isLoading }] =
    useLazyGetNodeDataQuery();

  const workflowRunId = useAppSelector(currentWorkflowRunId);
  const referenceRunId = useAppSelector(currentReferenceRunId);
  const currentWFStatus = useAppSelector(workflowRunningStatus);
  const [parentHeight, setParentHeight] = useState(isExpanded ? 900 : 300);
  const debouncedSetParentHeight = debounce(setParentHeight, 50);

  const { nodeId } = useAppSelector(
    selectPanel(WORKFLOW_PANELS.DataPreviewPanel)
  );

  const selectedNode = useMemo(() => {
    if (!nodeId) return;
    const { data: nodeData } = getNode(nodeId) as Node;
    return nodeData as NodeType;
  }, [nodeId, getNode]);

  const columns = React.useMemo(() => {
    if (!data?.response.data?.headers) return [];
    return data?.response.data?.headers?.map((header: string) => ({
      header: header,
      accessorKey: header,
    }));
  }, [data?.response.data?.headers]);

  const table = useReactTable({
    data: data?.response?.data?.rowData ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchNextPage = async (currentPage: number) => {
    const hasWfRanAtleastOnce = currentWFStatus !== NODE_STATUS.DEFAULT;
    const wfRunId = hasWfRanAtleastOnce ? workflowRunId : referenceRunId;

    const datasetParamerterObj = selectedNode?.parameters?.find(
      (parameter) => parameter.name === "dataset_id"
    );
    const datasetId =
      datasetParamerterObj?.defaultValue ?? datasetParamerterObj?.value;

    await fetchNodeData({
      nodeIoDetailsId: ioDetailId,
      workflowRunId: wfRunId ?? "",
      page: `${currentPage}`,
      previewType: type,
      // nodeId: selectedTabNode.nodeId,
      workflowNodeId: selectedNode?.workflowNodeId ?? "",
      index: tabIndex,
      datasetId: datasetId,
      analysisId: analysisId,
    });
    page.current = currentPage + 1;
    isPaginating.current = false;
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);

  /**
   * This conditon is there because table.getRowModel() crashes when data is null
   * Similarly other conditions involving getRowModel() are there because it crashes when data is null
   **/
  const count = isLoading || !data ? 0 : table.getRowModel().rows.length;

  const virtualizer = useVirtualizer({
    count: count,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  useEffect(() => {
    const parentRef = ref as MutableRefObject<HTMLDivElement>;
    if (parentRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries) || !entries.length) return;
        debouncedSetParentHeight(entries[0].contentRect.height);
      });

      resizeObserver.observe(parentRef.current as Element);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [ref]);

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement && nodeId) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        const remainingScrollHeight = scrollHeight - scrollTop - clientHeight;
        const shouldFetchNext =
          page.current < 2 || data?.response.data?.hasNext;
        if (
          remainingScrollHeight < 300 &&
          !isPaginating.current &&
          shouldFetchNext
        ) {
          isPaginating.current = true;
          fetchNextPage(page.current);
        }
      }
    },
    [isLoading, data, nodeId]
  );

  //reset page number on nodeId change
  useEffect(() => {
    page.current = 1;
    isPaginating.current = false;
  }, [nodeId]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <Box className="relative">
      <Box
        className={clsx(
          "overflow-auto w-full relative",
          `!h-[calc(${parentHeight}px - 80px)]`
        )}
        ref={tableContainerRef}
        h={parentHeight - 80}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      >
        <Box
          style={{
            height: `${virtualizer.getTotalSize()}px`,
          }}
        >
          {!isLoading && (
            <Table
              width="100%"
              variant="simple"
              className="border-r border-l border-gray-400 rounded-md"
              __css={{
                borderCollapse: "separate",
                borderSpacing: 0,
              }}
            >
              <Thead position="sticky" top={0} zIndex={1} bg={"white"}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      const meta = header.column.columnDef.meta;
                      return (
                        <Th
                          width={`${header.column.getSize()}%`}
                          maxWidth={`${header.column.getSize()}%`}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          borderY={"1px solid"}
                          borderRight={"1px solid"}
                          borderColor="gray.400"
                          backgroundColor="gray.50"
                          key={header.id}
                          isNumeric={meta?.isNumeric}
                          onClick={header.column.getToggleSortingHandler()}
                          role="button"
                          padding="0.75rem 1rem"
                          style={{
                            width: header.getSize(),
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            textTransform: "none"
                          }}
                        >
                          <Flex align="center">
                            <chakra.span
                              className={clsx(
                                "inline-flex items-center text-gray-700"
                              )}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </chakra.span>
                            {header.column.getCanSort() && (
                              <chakra.span pl="4">
                                {header.column.getIsSorted() ? (
                                  header.column.getIsSorted() === "desc" ? (
                                    <BsArrowDown
                                      aria-label="sorted descending"
                                      title="sorted descending"
                                    />
                                  ) : (
                                    <BsArrowUp
                                      aria-label="sorted ascending"
                                      title="sorted ascending"
                                    />
                                  )
                                ) : (
                                  <BsArrowDownUp
                                    aria-label="sort"
                                    title="sort"
                                  />
                                )}
                              </chakra.span>
                            )}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody
                css={{
                  "&::after": {
                    display: "block",
                    content: "''",
                    // this ensures the table takes up as much size as the total elements by adding padding to the end
                    height: `${
                      virtualizer.getTotalSize() - virtualizer.scrollRect.height
                    }px`,
                  },
                }}
              >
                {data &&
                  virtualizer.getVirtualItems().map((virtualRow, index) => {
                    const row = table.getRowModel().rows[virtualRow.index];
                    return (
                      <Tr
                        key={row.id}
                        _hover={{
                          cursor: "pointer",
                        }}
                        style={{
                          height: `${virtualRow.size}px`,
                          transform: `translateY(${
                            virtualRow.start - index * virtualRow.size
                          }px)`,
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                          return (
                            <Td
                              overflow={"hidden"}
                              whiteSpace="pre"
                              textOverflow={"ellipsis"}
                              key={cell.id}
                              maxWidth="400px"
                              borderRight={"1px solid"}
                              borderColor="gray.200"
                              paddingLeft={"0.75rem"}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          )}
        </Box>
        {!data && !isLoading && (
          <Flex className="justify-center w-full items-center mt-[120px]">
            No Data
          </Flex>
        )}
      </Box>
      {isLoading && (
        <Flex className="justify-center w-full h-full bg-white gap-3 top-0 items-center absolute mt-auto">
          <Spinner />
          <Box>Loading</Box>
        </Flex>
      )}
      {isFetching && !isLoading && (
        <Flex className="justify-center gap-3 py-1 bg-white w-full items-center absolute bottom-0 h-fit mt-auto">
          <Spinner size="sm" />
          <Box>Loading</Box>
        </Flex>
      )}
    </Box>
  );
});
