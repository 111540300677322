import { configureStore } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { dataManagerAPI } from "@/features/data-manager/api";
import { dataManagerReducer } from "@/features/data-manager/redux";
import {
  dataTransformationReducer,
  dataTransformationAPI,
} from "@/features/data-transformation";
import { aiPlaygroundAPI } from "@/features/llm-prompt/api/llm-api.ts";
import { llmReducer } from "@/features/llm-prompt/redux";
import { orgSettingsReducer } from "@/features/org-settings/redux";
import { userManagerReducer, userManagerApi } from "@/features/user-manager";
import { userSettingsReducer } from "@/features/user-settings/redux";
import { workflowManagerApi } from "@/features/workflow-studio/api/workflow-api";
import {
  flowAutoSaveReducer,
  workflowStudioReducer,
  workflowReducer as workflowReducerPersist,
  dataPreviewReducer,
} from "@/features/workflow-studio/redux";
import { wsManagerApi, wsManagerReducer } from "@/features/ws-manager";

import authReducer from "../slices/auth-slice.ts";
import permissionReducer from "../slices/permisson-slice.ts";

import rootReducer from "./rootReducer";

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
});

const dataManagerPersistConfig = {
  key: "dataManagerSlice",
  version: 1,
  storage: storage,
  whitelist: [""],
};

const workflowPersistConfig = {
  key: "workflow",
  version: 1,
  storage: storage,
  whitelist: [""],
};

const workflowReducer = persistReducer(
  workflowPersistConfig,
  workflowReducerPersist
);
const dataManagerPersistReducer = persistReducer(
  dataManagerPersistConfig,
  dataManagerReducer
);

export const store = configureStore({
  reducer: {
    authReducer,
    rootReducer,
    userSettingsReducer,
    orgSettingsReducer,
    wsManagerReducer,
    userManagerReducer,
    dataManagerPersistReducer,
    [wsManagerApi.reducerPath]: wsManagerApi.reducer,
    [workflowManagerApi.reducerPath]: workflowManagerApi.reducer,
    [dataManagerAPI.reducerPath]: dataManagerAPI.reducer,
    [dataTransformationAPI.reducerPath]: dataTransformationAPI.reducer,
    [userManagerApi.reducerPath]: userManagerApi.reducer,
    [aiPlaygroundAPI.reducerPath]: aiPlaygroundAPI.reducer,
    workflowStudioReducer,
    workflowReducer,
    permissionReducer,
    flowAutoSaveReducer,
    dataPreviewReducer,
    dataTransformationReducer,
    llmReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat([wsManagerApi.middleware])
      .concat([dataManagerAPI.middleware])
      .concat([workflowManagerApi.middleware])
      .concat([userManagerApi.middleware])
      .concat([dataTransformationAPI.middleware])
      .concat([aiPlaygroundAPI.middleware]);
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
