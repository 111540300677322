import { Box, Divider, Flex, Input, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { MdsSearchRound } from "react-icons-with-materialsymbols/mds";
import { useDebounce } from "use-debounce";

import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { closeModal } from "@/slices/modal-slice";

import { useGetDestinationsQuery } from "../../api";
import { AddDatasetNavigation } from "../../components/common/navigation";
import { SelectConnector } from "../../components/common/select-connector";
import { ConnectorSources } from "../../types";
import { groupSourcesByCategory } from "../../utils/connector-helpers";

const ExportConnectorsBody = () => {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState("");
  const [selectedNavValue, setSelectedNavValue] = useState("All");

  const [destinationConnectors, setdestinationConnectors] =
    useState<ConnectorSources>({});

  const { data, isLoading } = useGetDestinationsQuery();

  const { modalProps } = useAppSelector((s) => s.rootReducer.modals);

  useEffect(() => {
    const destinations = data?.response?.data?.results;
    if (!destinations) return;

    const groupedByCategory = groupSourcesByCategory(destinations);
    setdestinationConnectors(groupedByCategory);
  }, [data]);

  const [debouncedValue] = useDebounce(search, 500);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const destinationsList = useMemo(() => {
    if (debouncedValue) {
      return destinationConnectors[selectedNavValue]?.filter((item) =>
        item.name.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    }
    return destinationConnectors[selectedNavValue] || [];
  }, [destinationConnectors, selectedNavValue, debouncedValue]);

  const handleConnectorButtonClick = (definition: any) => {
    dispatch(closeModal());
    const url = `/analysis/${modalProps.analysisId}/data-manager/destination/${definition.id}`;
    modalProps.navigate(url, {
      state: { destination: definition, datasetId: modalProps.dataset.id },
    });
  };

  return (
    <div>
      {isLoading ? (
        <Flex className="items-center justify-center" minH={"40vh"}>
          <Spinner size="sm" />
          <Box className="ml-2" pt="2xl">
            Loading...
          </Box>
        </Flex>
      ) : (
        <Flex className="h-[calc(100vh/1.5)] w-full">
          <AddDatasetNavigation
            connectorSources={destinationConnectors}
            setSelectedNavValue={setSelectedNavValue}
          />
          <Flex className="flex-col h-full" style={{ width: "inherit" }}>
            <Flex className="items-center text-gray-600 pl-3">
              <MdsSearchRound fontSize={24} />
              <Input
                className="w-full !border-none"
                onChange={(e) => handleInputChange(e)}
                placeholder="Search Destinations"
                type="search"
              />
            </Flex>
            <Divider className="!my-0" />
            <SelectConnector
              sourceListData={destinationsList}
              connectorType="destination"
              onSelectConnectorDefinition={handleConnectorButtonClick}
            />
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default ExportConnectorsBody;
