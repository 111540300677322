import { APP_BASE_URL, ENVIRONMENTS } from "@/utils/constants";

async function parseJSON(response: Response) {
  return await response.json().then((json) => ({
    status: response.status,
    ok: response.ok,
    json,
  }));
}

export const fetchRegionsForEmail = async (email: string) => {
  const code = import.meta.env.VITE_LOGIN_API_CODE;
  const host = window.location.hostname;

  const parts = host.split(".");
  
  // Find if the URL contains 'dev' or 'qa'
  const envPart = parts.find(part => part === ENVIRONMENTS.DEV || part === ENVIRONMENTS.QA);
  

  const url = envPart
    ? `https://backend.${envPart}.${APP_BASE_URL}/api/retrieve_regions?email=${email}&code=${code}`
    : `https://backend.${APP_BASE_URL}/api/retrieve_regions?email=${email}&code=${code}`;

  try {
    const response = await fetch(url).then(parseJSON);
    console.log("fetch response", response);
    if(response.status === 404){
      return {
        status: 404,
        ok: false,
        json: { message: "Could not fetch regions" },
      };
    }
    if (response.status !== 200 || !response.ok) {
      throw new Error(
        (response.json.message as string) ?? "Could not fetch regions"
      );
    }
    return response;
  } catch (error) {
    console.log("fetch error", error);
    throw new Error("Could not fetch regions");
  }
};

export const getRedirectUrlAfterLogout = () => {
  const host = window.location.hostname.split(".");
  const envPart = host.find(part => part === ENVIRONMENTS.DEV || part === ENVIRONMENTS.QA);
  
  if (!envPart) {
    if(host.length > 4){
      return host.slice(2).join(".");
    }
    
    if (host.length === 4) {
      // host = cus.ctnxt.ctk.com => ctnxt.ctk.com
      return host.slice(1).join(".");
    }
    // host = org2.cus.ctnxt.ctk.com => ctnxt.ctk.com
    return host.join(".");
  }
  
  // Dev or QA environment
  const envIndex = host.indexOf(envPart);
  if (envIndex === -1) return host.join("."); // Fallback

  // Keep everything from env part onwards
  return host.slice(envIndex).join(".");
};
