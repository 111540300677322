import { Button, Flex, ModalCloseButton, ModalFooter } from "@chakra-ui/react";
import { useState } from "react";
import { MdsSyncRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { useSaveEDAStepsMutation } from "@/features/data-transformation";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const SaveStep = () => {
  const dispatch = useDispatch();
  const [saveSteps] = useSaveEDAStepsMutation();
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    dispatch(closeModal());
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      await saveSteps({
        analysisId: modalProps.analysisId,
        edaId: modalProps.edaId,
        execute: false,
        confirm: true,
      });
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);

    modalProps.saveEda?.();

    modalProps.onUpdate?.();
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-440 !gap-y-0.5">
        <Flex className="relative px-5">
          <Flex className="!font-normal !text-lg !text-gray-900">
            Unsaved Changes found
          </Flex>
          <ModalCloseButton className="!mr-0 !-top-1" />
        </Flex>
        <ModalBody className="!px-5 !pb-4 !py-0 text-sm w-fit leading-[21px] text-gray-800">
          <Flex className="flex-col text-sm leading-[20px] text-gray-800">
            Some new changes in the transformation steps are yet to be saved.
            <Flex className="mt-4">
              You must save all new changes before updating the sample.
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex className="!px-6 w-full justify-between">
            <Button
              colorScheme="secondar"
              onClick={onClose}
              size="sm"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              colorScheme="dark"
              iconSpacing={1}
              isLoading={isLoading}
              loadingText="Saving steps"
              onClick={onSave}
              rightIcon={<MdsSyncRound strokeWidth={22} fontSize={20} />}
              size="sm"
              variant="solid"
            >
              Save & Update Sample
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
