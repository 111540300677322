import { EndpointBuilder } from "@reduxjs/toolkit/query";

import { IExportDatasetResponse } from "@/features/data-manager/types";
import { AccessListSchema } from "@/features/ws-manager/types";
import { ApiResponse, PaginationBody } from "@/types";
import { CATEGORY } from "@/utils/enums";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import {
  CreateUserAccessPayload,
  DeleteAccessPayload,
  IGetOrgResponse,
  IGetTenantResponse,
  IGetUserIdResponse,
  IInviteUserRequest,
  IVerifyEmailResponse,
  UpdateAccessPayload,
  UserRoleSchema,
  UsersSchema,
} from "../types";

import { userApi } from "./api";
import { UserManagerEndpoints } from "./api-endpoints";

const isPaginationBody = (body: any): body is PaginationBody => {
  return typeof body === "object" && "next" in body;
};

const mergeCacheKeys = (currentCache: any, newItems: any, { arg }: any) => {
  if (!arg || !arg.next) return newItems;
  const pagination = newItems.response.pagination;

  const newData = [
    ...(currentCache.response.data ?? []),
    ...(newItems.response.data ?? []),
  ];

  return {
    ...newItems,
    response: {
      pagination: pagination,
      data: newData,
    },
  };
};

export const userManagerApi = userApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    getUsersList: build.query<
      ApiResponse<UsersSchema[]>,
      PaginationBody | void
    >({
      query: (body) => {
        if (!body || !isPaginationBody(body)) {
          return {
            url: UserManagerEndpoints.getUsers(),
          };
        }

        return {
          url: body.next,
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: mergeCacheKeys,
      providesTags: ["Users"],
    }),
    getUserFromQuery: build.query<
      ApiResponse<UsersSchema[]>,
      { query: string }
    >({
      query: ({ query }) => {
        return {
          url: UserManagerEndpoints.getUsers(),
          params: {
            user_name: query,
          },
        };
      },
    }),
    getUserListForObject: build.query<
      ApiResponse<UserRoleSchema[]>,
      { objectType: string; objectId: string }
    >({
      query: ({ objectType, objectId }) => {
        return {
          url: UserManagerEndpoints.getUsersListForObject(objectId),
          params: {
            "object-type": objectType,
          },
        };
      },
      keepUnusedDataFor: 0,
    }),
    getAccessList: build.query<
      ApiResponse<AccessListSchema>,
      { scope: string; permission: string }
    >({
      query: ({ scope, permission }) => {
        return {
          url: UserManagerEndpoints.getAccessList(scope, permission),
        };
      },
      providesTags: (_, __, args) => [
        { type: "WorkspaceAccessList", id: args.scope },
      ],
      keepUnusedDataFor: 0,
    }),
    createUserAccess: build.mutation<ApiResponse<any>, CreateUserAccessPayload>(
      {
        query: (data) => {
          return {
            url: UserManagerEndpoints.createUserAccess(),
            method: "PUT",
            body: keysToSnake(data),
          };
        },
        invalidatesTags: [
          { type: "WorkspaceAccessList", id: "project" },
          { type: "WorkspaceAccessList", id: "analysis" },
        ],
      }
    ),
    updateUserAccess: build.mutation<
      ApiResponse<any>,
      { data: UpdateAccessPayload }
    >({
      query: ({ data }) => {
        return {
          url: UserManagerEndpoints.updateUserAccess(),
          method: "PUT",
          body: keysToSnake(data),
        };
      },
      invalidatesTags: [
        { type: "WorkspaceAccessList", id: "project" },
        { type: "WorkspaceAccessList", id: "analysis" },
      ],
    }),
    deleteUserAccess: build.mutation<
      ApiResponse<any>,
      { objectId: string; data: DeleteAccessPayload }
    >({
      query: ({ objectId, data }) => {
        return {
          url: UserManagerEndpoints.deleteUserAccess(objectId),
          method: "DELETE",
          body: keysToSnake(data),
        };
      },
      invalidatesTags: [
        { type: "WorkspaceAccessList", id: "project" },
        { type: "WorkspaceAccessList", id: "analysis" },
      ],
    }),
    getRoles: build.query<
      ApiResponse<string[]>,
      { objectId: string; type: CATEGORY }
    >({
      query: ({ objectId, type }) => {
        return {
          url: UserManagerEndpoints.getRoles(),
          params: {
            scope: type,
            objectId: objectId,
          },
        };
      },
    }),
    validaterUserMails: build.query<
      ApiResponse<IVerifyEmailResponse>,
      { emails: string[] }
    >({
      query: ({ emails }) => {
        return {
          method: "POST",
          url: UserManagerEndpoints.verifyUserEmails,
          body: {
            emails: emails,
          },
        };
      },
    }),
    inviteUsers: build.mutation<
      ApiResponse<{
        emails: string[];
        errors: string[];
        userIds: string[];
      }>,
      IInviteUserRequest
    >({
      query: (data) => {
        return {
          url: UserManagerEndpoints.inviteUsers,
          method: "POST",
          body: keysToSnake(data),
        };
      },
      invalidatesTags: ["Users"],
    }),
    getExportToken: build.mutation<
      ApiResponse<IExportDatasetResponse>,
      { analysisId: string; datasetId: string }
    >({
      query: ({ analysisId, datasetId }) => {
        return {
          url: UserManagerEndpoints.getExportToken,
          method: "POST",
          headers: {
            "analysis-id": analysisId,
          },
          body: {
            analysis_id: analysisId,
            dataset_id: datasetId,
          },
        };
      },
    }),
    getTenantId: build.query<ApiResponse<IGetTenantResponse>, void>({
      query: () => {
        return {
          url: UserManagerEndpoints.getTenantId,
        };
      },
    }),
    getUserId: build.query<ApiResponse<IGetUserIdResponse>, { oid: string }>({
      query: ({ oid }) => {
        return {
          url: UserManagerEndpoints.getUserId,
          headers: {
            oid: oid,
          },
        };
      },
    }),
    createOrguser: build.mutation<ApiResponse<any>, { data: any }>({
      query: ({ data }) => {
        return {
          url: UserManagerEndpoints.createOrgUser,
          method: "POST",
          body: data,
        };
      },
    }),
    createOrgAdmin: build.mutation<ApiResponse<any>, { userId: string }>({
      query: ({ userId }) => {
        return {
          url: UserManagerEndpoints.createOrgAdmin,
          method: "POST",
          body: {
            user_id: userId,
          },
        };
      },
    }),
    removeOrgAdmin: build.mutation<ApiResponse<any>, { userId: string }>({
      query: ({ userId }) => {
        return {
          url: UserManagerEndpoints.removeOrgAdmin,
          method: "POST",
          body: {
            user_id: userId,
          },
        };
      },
    }),
    removeOrgUser: build.mutation<
      ApiResponse<any>,
      { userId: string; tenantName?: string }
    >({
      query: ({ userId, tenantName }) => {
        return {
          url: UserManagerEndpoints.removeOrgUser,
          method: "DELETE",
          body: {
            user_id: userId,
            tenant_name: tenantName,
          },
        };
      },
    }),
    getOrgsForUser: build.query<
      ApiResponse<IGetOrgResponse>,
      { email: string }
    >({
      query: ({ email }) => {
        return {
          url: UserManagerEndpoints.getOrganizationsForUser(email),
        };
      },
    }),
    removeUserFromPlatform: build.mutation<
      ApiResponse<string[]>,
      { userId: string } | { email: string; azure_oid: string; region: string }
    >({
      query: (data) => {
        return {
          url: UserManagerEndpoints.removeUserFromPlatform,
          method: "POST",
          body: keysToSnake(data),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersListQuery,
  useGetUserFromQueryQuery,
  useLazyGetUserFromQueryQuery,
  useLazyGetUsersListQuery,
  useLazyGetUserListForObjectQuery,
  useGetUserListForObjectQuery,
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useGetAccessListQuery,
  useLazyGetAccessListQuery,
  useUpdateUserAccessMutation,
  useCreateUserAccessMutation,
  useDeleteUserAccessMutation,
  useInviteUsersMutation,
  useGetExportTokenMutation,
  useGetTenantIdQuery,
  useLazyGetTenantIdQuery,
  useGetUserIdQuery,
  useLazyGetUserIdQuery,
  useCreateOrguserMutation,
  useCreateOrgAdminMutation,
  useRemoveOrgAdminMutation,
  useRemoveOrgUserMutation,
  useGetOrgsForUserQuery,
  useLazyGetOrgsForUserQuery,
  useLazyValidaterUserMailsQuery,
  useRemoveUserFromPlatformMutation,
} = userManagerApi;
