import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getIncomers, Node, useReactFlow } from "reactflow";

import {
  showLLMConfigPanel,
  useSaveWorkflowMutation,
} from "@/features/workflow-studio";
import {
  currentReferenceRunId,
  currentWorkflowRunId,
  workflowRunningStatus,
} from "@/features/workflow-studio/redux";
import { NodeType } from "@/features/workflow-studio/types";
import { WorkflowNodeSchema } from "@/features/workflow-studio/types/workflow-types";
import { NODE_STATUS } from "@/features/workflow-studio/utils/constants";
import { ConvertFlowtoWorkflowPayload } from "@/features/workflow-studio/utils/transform-response";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";

import {
  useCreateAiPlaygroundMutation,
  useLazyGetAIPlaygroundBySourceQuery,
  useLazyGetSourceSchemaDetailsQuery,
  useUpdatePlaygroundMutation,
} from "../api";
import { setInputColumns } from "../redux";
import { CreateAiPlaygroundPayload } from "../types";

const updateWFNodeId = (node: Node, newNode: WorkflowNodeSchema) => {
  return {
    ...node,
    data: {
      ...node.data,
      workflowNodeId: newNode.workflowNodeId,
    } as NodeType,
  };
};

const updateNodesWithNewNodes = (
  nodeId: string | undefined,
  nds: Node[],
  newNodes: WorkflowNodeSchema[]
) => {
  let sourceId;
  const updatedNodes = nds.map((node) => {
    const newNode = newNodes.find(
      (n: WorkflowNodeSchema) => n.uiNodeId === node.id
    );
    if (!newNode) return node;
    if (nodeId && newNode.uiNodeId === nodeId) {
      sourceId = newNode.workflowNodeId;
    }
    return updateWFNodeId(node, newNode);
  });
  return {
    nodes: updatedNodes,
    sourceId,
  };
};

const useWorkflowPlayground = () => {
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [hasErrors, setHasErrors] = useState<string>();
  const llmConfig = useAppSelector(showLLMConfigPanel);

  const params = useParams();
  const dispatch = useAppDispatch();
  const workflowRunId = useAppSelector(currentWorkflowRunId);
  const referenceRunId = useAppSelector(currentReferenceRunId);
  const currentWFStatus = useAppSelector(workflowRunningStatus);

  const { getNode, toObject, setNodes, getNodes, getEdges } = useReactFlow();
  const [saveWorkflow, { isLoading: savingWF }] = useSaveWorkflowMutation();
  const [getConnectedSourceData] = useLazyGetSourceSchemaDetailsQuery();
  const [updatePlayground] = useUpdatePlaygroundMutation();
  const [getAIPlayground, { data, isLoading: loadingPlayground }] =
    useLazyGetAIPlaygroundBySourceQuery();
  const [createPlayground, { isLoading: creatingPlayground }] =
    useCreateAiPlaygroundMutation();

  const saveWF = useCallback(async () => {
    let savedWfRunId;
    const response = await saveWorkflow({
      analysisId: params.analysisId!,
      workflow: ConvertFlowtoWorkflowPayload(toObject()),
      workflowId: params.editorId!,
    }).unwrap();
    const currentWF = response.response.data?.workflows[0];
    const newNodes = currentWF?.workflowNodes;
    if (!newNodes || !currentWF) return;

    if (currentWF.workflowStatus === NODE_STATUS.DEFAULT) {
      savedWfRunId = currentWF.referenceRunId;
    } else {
      savedWfRunId = currentWF?.workflowRunId;
    }

    const { nodes, sourceId } = updateNodesWithNewNodes(
      llmConfig.nodeId as string,
      getNodes(),
      newNodes
    );
    setNodes(nodes);

    return { sourceId, savedWfRunId };
  }, [params, llmConfig, setNodes, saveWorkflow, toObject]);

  const initializePlayground = useCallback(async () => {
    function getNodeIfExists(nodeId: string) {
      const node = getNode(nodeId);
      if (!node) {
        setIsInitializing(false);
        throw new Error("Node not found");
      }
      return node;
    }

    function getNodeData(node: Node) {
      const nodeData = node?.data as NodeType;
      const sourceId = nodeData.workflowNodeId;
      const wfRunId =
        currentWFStatus === NODE_STATUS.DEFAULT
          ? referenceRunId
          : workflowRunId;
      return {
        sourceId,
        wfRunId,
        nodeData,
      };
    }

    function isInputDataAvailable(node: Node | undefined) {
      if (!node) return false;
      const incomingNodes = getIncomers(node, getNodes(), getEdges());
      let isIpAvailable = false;
      if (incomingNodes.length > 0) {
        const incomingNode = incomingNodes[0];
        const sourceNodeData = incomingNode.data as NodeType;
        isIpAvailable = sourceNodeData.isOutputDataAvailable ?? false;
      }
      return isIpAvailable;
    }

    const getNodeExistingParameters = (nodeData: NodeType) => {
      return nodeData.parameters.map((param) => ({
        name: param.name,
        id: param.parameterId,
        value: isEmpty(param.value) ? param.defaultValue : param.value,
      }));
    };

    const getSourceColumns = async (
      playgroundId: string | null | undefined
    ) => {
      if (playgroundId) {
        const response = await getConnectedSourceData({
          playgroundId: playgroundId ?? "",
        }).unwrap();
        const columns =
          response.response.data?.aiPlayground[0].sourceInputDataColumns ?? [];
        dispatch(setInputColumns(columns));
      }
    };

    const getPayload = (
      sourceId: string | null | undefined = null,
      wfRunId: string | null | undefined = null,
      addParams: boolean = false
    ): CreateAiPlaygroundPayload => {
      const node = getNode(llmConfig.nodeId as string);
      const nodeData = node?.data as NodeType;
      const isIpAvailable = isInputDataAvailable(node);

      const payload: CreateAiPlaygroundPayload = {
        name: "Test Playground",
        description: "Test Playground Description",
        sourceData: {
          workflowNodeId: sourceId as string,
          workflowId: params["editorId"]!,
          nodeIoDetailId: "5ab1474a-4910-4e44-a790-1e796ca39f99",
          workflowRunId: wfRunId ?? null,
          isInputDataAvailable: isIpAvailable,
          analysisId: params["analysisId"]!,
          workspaceId: "518cbce6-717f-4962-9d3b-d686829ccb9b",
          nodeUsageInstanceId: nodeData.nodeUsageInstanceId,
        },
        textGeneration: {
          title: "Test Playground",
          description: null,
        },
      };

      if (addParams) {
        payload.sourceData.nodeParameters = getNodeExistingParameters(nodeData);
      }

      return payload;
    };

    const playGroundPutApi = async (payload: CreateAiPlaygroundPayload) => {
      const resp = await updatePlayground(payload).unwrap();
      return resp.response.data?.aiPlayground[0].id;
    };

    const playGroundPostApi = async (payload: CreateAiPlaygroundPayload) => {
      const resp = await createPlayground(payload).unwrap();
      return resp.response.data?.aiPlayground[0].id;
    };

    try {
      setIsInitializing(true);

      if (!llmConfig.nodeId) {
        setIsInitializing(false);
        return;
      }

      const node = getNodeIfExists(llmConfig.nodeId);
      let { sourceId, wfRunId } = getNodeData(node);

      // if (!sourceId) {
      // Save Workflow Before opening LLM PLayground
      const saveResp = await saveWF();
      sourceId = saveResp?.sourceId;
      wfRunId = saveResp?.savedWfRunId;
      // }

      let playgroundId = null;
      try {
        await getAIPlayground({
          sourceType: "workflow_node",
          sourceId: sourceId!,
        }).unwrap();

        const payload = getPayload(sourceId, wfRunId, false);
        playgroundId = await playGroundPutApi(payload);
      } catch (error) {
        if ((error as AxiosError).status === 404) {
          const payload = getPayload(sourceId, wfRunId, true);
          playgroundId = await playGroundPostApi(payload);
        }
      } finally {
        await getSourceColumns(playgroundId);
        setIsInitializing(false);
      }
    } catch (error) {
      setIsInitializing(false);
      setHasErrors((error as AxiosError).message);
    }
  }, [
    getNode,
    currentWFStatus,
    referenceRunId,
    workflowRunId,
    getNodes,
    getEdges,
    getConnectedSourceData,
    dispatch,
    llmConfig.nodeId,
    params,
    updatePlayground,
    createPlayground,
    saveWF,
    getAIPlayground,
  ]);

  return {
    saveWF,
    isInitializing,
    initializePlayground,
    savingWF,
    loadingPlayground,
    creatingPlayground,
    data,
    hasErrors,
  };
};

export default useWorkflowPlayground;
